<template>
  <div class="container">
      <br />

        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 p-lg-12 p-text-center">
                <Button
                label="Cerrar"
                icon="pi pi-times"
                @click="closeModalDetailsClients"
                class="p-button-outlined p-button-danger"
                :style="{ width: '150px' }"
                autofocus
                />
            </div>
        </div>
  </div>
</template>
<script>
//import dataClients from '../../../service/Clients'

export default {
  emits: ['closeDetailsModalClients'],
  props: {},
  data() {
    return {
        loading:false
    };
  },
  methods: {
    closeModalDetailsClients() {
      this.$emit('closeDetailsModalClients');
    },
    
  },
  created(){
      //this.dataClients = new dataClients();
  }
};
</script>
