<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Facturacion</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-credit-card"></span>
          </button>
        </template>
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="p-d-flex p-text-bold">
              <h4 style="color:#5D0C28;">En Desarrollo...</h4>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from "../../store";
export default {
  components: {},
  data() {
    return {
      moduleUser: store.state.moduleApp,
    };
  },
  methods: {
    
  },
  created() {
    if (!(this.moduleUser.findIndex((data) => data.vista === "SalesInvoices") > -1)) { this.$router.push("/"); }
  },
  mounted() {
    
  },
};
</script>