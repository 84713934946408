import 'primevue/resources/themes/saga-blue/theme.css';
//import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import './assets/layout/layout.scss';

import { createApp, reactive } from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import store from './store';
// Components UI

import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FullCalendar from 'primevue/fullcalendar';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import Ripple from 'primevue/ripple';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import TabMenu from 'primevue/tabmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import Tree from 'primevue/tree';

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

//config modules.
const app = createApp(App);
//Axios.defaults.baseURL = (process.env.NODE_ENV === 'production')?'https://pagos.ugbstore.ugb.edu.sv/app/':'https://localhost:44317/';
Axios.defaults.baseURL = (process.env.NODE_ENV === 'production')?'https://devpagosugbstore.ugb.edu.sv/app/':'https://localhost:44317/';
Axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.state.utoken;
app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });

app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(store);
app.use(router);
app.use(VueAxios, Axios);

app.component('AutoComplete', AutoComplete);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Chart',Chart);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FullCalendar', FullCalendar);
app.component('Fieldset',Fieldset);
app.component('InputText', InputText);
app.component('InputMask',InputMask);
app.component('InputNumber', InputNumber);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Panel', Panel);
app.component('Password', Password)
app.component('ProgressBar', ProgressBar);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('TabMenu', TabMenu);
app.component('Textarea',Textarea);
app.component('TabPanel', TabPanel);
app.component('Toast', Toast);
app.component('Toolbar',Toolbar);
app.component('Tree',Tree);

app.mount('#app');