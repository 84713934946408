<template>
<Panel>
    <template #header>
        <div class="p-text-center">
            <b>Listado general de clientes</b>
        </div>
    </template>
    <div class="container">
        <div class="p-grid">
            <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-text-right">
                    <Button
                        class="p-button-raised p-button-outlined p-button-info p-mt-4"
                        label="Generar Informe"
                        icon="pi pi-file-pdf"
                        iconPos="left"
                        @click="generateReport"
                        style="width:250px;"
                    />
                </div>
            </div>
            <div class="p-col-12">
                <object id="iframePDF" :data="dataPDF" type="application/pdf" width="100%" height="550px">
                    <iframe :src="dataPDF" width="100%" height="550px" style="border: none;">
                        This browser does not support PDFs. Please download the PDF to view it: 
                    </iframe>
                </object>
            </div>
            </div>
        </div>
    </div>
</Panel>
</template>
<script>
import clientsReports from "../../../service/ClientsReports"
export default {
  data() {
    return {
      dataPDF:null
    };
  },
  methods: {
      generateReport(){
        this.clientsReports.getListClients()
        .then(response => {
            if(response.data.type=="text/html"){
                this.dataPDF= "";
                this.$toast.add({ severity: "error", summary: "Error!", detail: "No se encontraron registros", life: 3000, });
            }else{
                var newBlob = new Blob([response.data], {type: "application/pdf"})
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob, 'ListadoClientesPDF.pdf');
                    return;
                }
                const data =  (window.URL || window.webkitURL).createObjectURL(newBlob);
                this.dataPDF = data;
                if(navigator.userAgent.indexOf("Firefox") !== -1){
                    setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                    }, 100);
                }
            }
        })
        .catch((error) => {
            if(error.response.status===401){
                console.info("Error 401! Contacte con el administrador de sistema");
            }else if(error.response.status===400){
                console.info(error.response.data.message);
            }else{
                console.info("Error 500! Contacte con el administrador de sistema");
            }
        });
      }
  },
  created() {
    this.clientsReports = new clientsReports();
  },
  mounted() {},
};
</script>