<template>
    <object id="myiframe" :data="dataPDF" type="application/pdf" width="100%" height="875px">
        <iframe :src="dataPDF" width="100%" height="875px" style="border: none;">
            This browser does not support PDFs. Please download the PDF to view it: 
        </iframe>
    </object>
</template>
<script>
import dataSales from '../../../service/Vents'
export default {
    props: {
        codigo: Number,
    },
    data(){
        return{
            dataPDF:null
        }
    },
    methods:{
        getVoucher(){
            this.dataSales.getVoucherSalesDetails(this.codigo)
            .then(response => {
                /*const blob = new Blob([response.data]);
                const objectUrl = URL.createObjectURL(blob);
                //this.dataPDF = 'https://localhost:44317/Ventas/ComprobanteVentaPDF/1014';
                this.dataPDF = objectUrl;
                */
                var newBlob = new Blob([response.data], {type: "application/pdf"})
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob, 'ComprobanteVentaPDF.pdf');
                    return;
                }
                const data =  (window.URL || window.webkitURL).createObjectURL(newBlob);
                this.dataPDF = data;
                if(navigator.userAgent.indexOf("Firefox") !== -1){
                    setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    console.info("Error 401! Contacte con el administrador de sistema");
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    console.info("Error 500! Contacte con el administrador de sistema");
                }
            });
        }
    },
    created(){
        this.dataSales = new dataSales();
    },
    mounted(){
        this.getVoucher();
    }
}
</script>
