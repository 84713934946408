import axios from 'axios';
export default class LoginUser {
    async access(uname, upass){
        return await axios({
            method: 'post',
            url: '/login',
            data: {
                username: uname,
                userpass: upass
              }
        });
    }
}