<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Administracion de regional y sedes de sistema</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-home"></span>
          </button>
        </template>
        <div class="p-grid">
          <div class="p-col-6 p-md-6 p-lg-6">
            <DataTable :value="listAllRegional" showGridlines responsiveLayout="scroll" class="p-datatable-sm" v-model:selection="selectedRegional" selectionMode="single" dataKey="codigo" @rowSelect="onRowSelect">
              <template #header>
                    <div class="table-header">
                        <h4>Regionales Registradas</h4>
                        <!--<Button icon="pi pi-refresh" />-->
                    </div>
                </template>
                <Column field="codigo" class="p-text-center" header="Codigo" headerStyle="background-color:#E0E0E0;">
                  <template #body="slotProps">
                    <b>{{slotProps.data.codigo}}</b>
                  </template>
                </Column>
                <Column field="nombre" class="p-text-center" header="Nombre Regional" headerStyle="background-color:#E0E0E0;"></Column>
                <Column field="estado" class="p-text-center" header="Estado" headerStyle="background-color:#E0E0E0;">
                  <template #body="slotProps">
                      <Badge v-if="slotProps.data.estado" value="Activo" severity="success"></Badge>
                      <Badge v-else value="Inactivo" severity="danger"></Badge>
                  </template>
                </Column>
            </DataTable>
          </div>
          <div class="p-col-6 p-md-6 p-lg-6">
            <DataTable :value="listSelectedBranch" responsiveLayout="scroll" class="p-datatable-sm">
              <template #header>
                    <div class="table-header">
                        <h4>Sucursales Registradas</h4>
                        <!--<Button icon="pi pi-refresh" />-->
                    </div>
                </template>
                <Column field="codigo" class="p-text-center" header="Codigo" bodyStyle="background-color:#E3F2FD;" headerStyle="background-color:#E0E0E0;">
                  <template #body="slotProps">
                    <b>{{slotProps.data.codigo}}</b>
                  </template>
                </Column>
                <Column field="descripcion" class="p-text-center" header="Nombre Sucursal" bodyStyle="background-color:#E3F2FD;" headerStyle="background-color:#E0E0E0;"></Column>
                <Column field="regional_nombre" class="p-text-center" header="Regional" bodyStyle="background-color:#E3F2FD;" headerStyle="background-color:#E0E0E0;"></Column>
            </DataTable>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import dataRegBranch from "../../service/RegionalBranch"
export default {
  components: {},
  data() {
    return {
      moduleUser: store.state.moduleApp,
      listAllRegional:[],
      listAllBranch:[],
      listSelectedBranch:[],
      selectedRegional:null
    };
  },
  methods: {
    fillBranchs(){
      this.dataRegBranch.listBranch()
      .then(response => {
        this.listAllBranch = response.data;
        this.fillregional();
      })
      .catch((error) => {
          if(error.response.status===401){
              console.info("Error 401! Contacte con el administrador de sistema");
          }else if(error.response.status===400){
              console.info(error.response.data.message);
          }else{
              console.info("Error 500! Contacte con el administrador de sistema");
          }
      });
    },
    fillregional(){
      this.dataRegBranch.listRegional()
      .then(response => {
        this.listAllRegional = response.data;
        this.selectedRegional = response.data[0];
        this.filllistSelectedBranch(this.selectedRegional.codigo);
      })
      .catch((error) => {
          if(error.response.status===401){
              console.info("Error 401! Contacte con el administrador de sistema");
          }else if(error.response.status===400){
              console.info(error.response.data.message);
          }else{
              console.info("Error 500! Contacte con el administrador de sistema");
          }
      });
    },
    filllistSelectedBranch(regional){
      this.listSelectedBranch=[];
      let istore = this.listAllBranch.findIndex(data => data.regional_id === regional);
      var data = (istore.length != "")?this.listAllBranch[istore]:null;
      this.listSelectedBranch.push(data);
    },
    onRowSelect(event) {
      this.filllistSelectedBranch(event.data.codigo);
    },
  },
  created() {
    this.dataRegBranch = new dataRegBranch();
    if (!(this.moduleUser.findIndex((data) => data.vista === "SystemCentral") > -1)) { this.$router.push("/"); }
  },
  mounted() {
    this.fillBranchs();
  },
};
</script>
<style lang="scss" scoped>
  .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
</style>