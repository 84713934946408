<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="content">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <div class="card">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4 p-md-4">
              <label for="cCarnet">Carnet/Codigo</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-id-card"></i>
                </span>
                <InputText id="cCarnet" type="text" maxlength="15" class="p-text-uppercase" v-model="localUClients.carnet"/>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccTipo">Tipo Cliente</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-folder"></i>
                </span>
                <Dropdown id="ccTipo" v-model="localUClients.tipo" :options="selectedType" optionLabel="nombre"/>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccEstado">Estado</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-info-circle"></i>
                </span>
                <Dropdown id="ccEstado" v-model="localUClients.estado" :options="selectedStatus" optionLabel="name"/>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="cCNombre">Nombre Completo</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-user"></i>
                </span>
                <span class="p-float-label">
                  <InputText id="cCNombre" type="text" maxlength="250" v-model="localUClients.nombre" />
                </span>
              </div>
            </div>
            <div class="p-field p-col-12">
              <label for="ccCorreo">Correo Electronico:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-envelope"></i>
                </span>
                <span class="p-float-label">
                  <InputText id="ccCorreo" type="text" :class="['', isEmailValid()]" v-model="localUClients.correo"/>
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccDUI">DUI</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask id="ccDUI" mask="99999999-9" placeholder="00000000-0" v-model="localUClients.dui" />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccNIT">NIT</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask id="ccNIT" mask="9999-999999-999-9" placeholder="0000-000000-000-0" v-model="localUClients.nit" />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccNRC">NRC</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask id="ccNRC" mask="999999-9" placeholder="000000-0" v-model="localUClients.nrc" />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccContribuyente">Contribuyente</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-bookmark"></i>
                </span>
                <span class="p-float-label">
                  <Dropdown id="ccContribuyente" v-model="localUClients.contribuyente" :options="selectedTaxpayer" optionLabel="name" />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccCategoria">Categoria</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-bookmark"></i>
                </span>
                <span class="p-float-label">
                  <Dropdown id="ccCategoria" v-model="localUClients.categoria" :options="selectedCategory" optionLabel="name" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4 p-md-4">
              <label for="ccTelefono">Agregar numero de telefono</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-phone"></i>
                </span>
                <span class="p-float-label">
                  <InputMask id="ccTelefono" mask="9999-9999" placeholder="0000-0000" v-model="localUDPhone"  />
                </span>
                <Button icon="pi pi-plus" class="p-button-success p-button-outlined" @click="addClientPhone" />
              </div>
              <DataTable :value="localUClientsTel" stripedRows class="p-datatable-sm" responsiveLayout="scroll" >
                <Column field="codigo" class="p-text-center" style="width:50px">
                <template #body="slotProps">
                  <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text p-button-sm"
                  @click="delClientPhone(slotProps.data.codigo)"
                  />
                  </template>
                </Column>
                <Column field="telefono">
                  <template #body="slotProps">
                    <span >{{slotProps.data.telefono}}</span>
                  </template>
                </Column>
                <template #empty>
                  <div class="p-text-center">
                    No se encontraron registro.
                  </div>
                </template>
              </DataTable>
            </div>
            <div class="p-field p-col-8 p-md-8">
              <label for="ccDireccion">Agregar direccion:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-book"></i>
                </span>
                <span class="p-float-label">
                  <InputText id="ccDireccion" maxlength="250" v-model="localUDAddress" />
                </span>
                <Button icon="pi pi-plus" class="p-button-success p-button-outlined" @click="addClientAddress"/>
              </div>
              <DataTable :value="localUClientsDir" stripedRows  class="p-datatable-sm" responsiveLayout="scroll">
                <Column field="codigo" class="p-text-center" style="width:50px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-times" 
                      class="p-button-rounded p-button-danger p-button-text p-button-sm" 
                      @click="delClientAddress(slotProps.data.codigo)"
                      />
                  </template>
                  </Column>
                  <Column field="direccion">
                  <template #body="slotProps">
                      {{slotProps.data.direccion}}
                  </template>
                  </Column>
                  <template #empty>
                    <div class="p-text-center">
                      No se encontraron registro.
                    </div>
                  </template>
              </DataTable>
            </div>
          </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col p-text-center">
        <Button
          label="Cancelar Actualizacion"
          icon="pi pi-times"
          @click="closeModalUClients"
          class="p-button-outlined p-button-danger p-mr-2 p-mb-2"
          :style="{ width: '250px' }"
        />
        <Button
          label="Actualizar Cliente"
          icon="pi pi-save"
          class="p-button-outlined p-button-success p-mb-2"
          @click="valDataUClient"
          :style="{ width: '250px' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import dataClients from "../../../service/Clients";
export default {
  emits: ["openModalUClientsEvent", "refreshTableUClients"],
  props: {
    UClient: Number,
  },
  data() {
    return {
      loadUClients: [],
      localUClients: [],
      localUClientsDir: [],
      localUClientsTel: [],
      selectedType: [],
      selectedTaxpayer: [],
      selectedCategory: [],
      selectedStatus: [],
      localUDPhone:'',
      localUDAddress:'',
      regexMail:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    closeModalUClients() {
      this.$emit("openModalUClientsEvent");
    },
    refreshUClients() {
      this.$emit("refreshTableUClients");
    },
    isEmailValid() {
      return this.localUClients.correo == ""? "": this.regexMail.test(this.localUClients.correo)? "": "p-invalid";
    },
    async fillDataTaxpayer(){
      await this.dataClients.selectTaxpayer()
      .then(response => {
            this.selectedTaxpayer = response;
        })
    },
    async fillDataCategory(){
      await this.dataClients.selectCategory()
      .then(response => {
        this.selectedCategory = response;
      })
    },
    async fillDataStatus(){
      await this.dataClients.selectStatusClient()
      .then(response => {
        this.selectedStatus = response;
      })
    },
    async fillDataSelectType(){
      await this.dataClients.getType()
        .then(response => {
            this.selectedType = response.data;
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
        });
    },
    async fillDataAdress(val){
      await this.dataClients.getAddress(val)
        .then(response => {
            this.localUClientsDir = response.data;
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                console.log(error.response.data.message);
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
            this.localUClientsDir=[];
        });
    },
    async fillDataPhone(val){
      await this.dataClients.getNumberPhone(val)
        .then(response => {
            this.localUClientsTel = response.data;
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                console.log(error.response.data.message);
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
            this.localUClientsTel = [];
        });
    },
    async loadDataClient(){
      await this.fillDataTaxpayer();
      await this.fillDataCategory();
      await this.fillDataSelectType();
      await this.fillDataStatus();
        await this.dataClients.getClientsID(this.UClient)
        .then(response => {
          this.loadUClients = response.data[0];
          this.localUClients = response.data[0];
          
          if(this.loadUClients.estado){
            this.loadUClients.estado = this.selectedStatus[1];
          }else{
             this.loadUClients.estado = this.selectedStatus[0];
          }
          
          if(this.loadUClients.categoria !=""){
            let sCategoria = JSON.stringify(this.loadUClients.categoria);
            let dCategoria = this.selectedCategory.findIndex(data => JSON.stringify(data.code) === sCategoria);
            this.loadUClients.categoria = (dCategoria.length != "")?this.selectedCategory[dCategoria]:this.selectedCategory[0];
          }else{
            this.loadUClients.categoria = this.selectedCategory[0];
          }
          
          if(this.loadUClients.contribuyente !=""){
            let sContribuyente = JSON.stringify(this.loadUClients.contribuyente);
            let dContribuyente = this.selectedTaxpayer.findIndex(data => JSON.stringify(data.code) === sContribuyente);
            this.loadUClients.contribuyente = (dContribuyente.length != "")?this.selectedTaxpayer[dContribuyente]:this.selectedTaxpayer[4];
          }else{
            this.loadUClients.contribuyente = this.selectedTaxpayer[4];
          }
          
          if(this.loadUClients.tipo !=""){
            let sTipo = this.loadUClients.tipo;
            let dType = this.selectedType.findIndex(data => data.codigo === sTipo);
            this.loadUClients.tipo = (dType.length != "")?this.selectedType[dType]:this.selectedType[0];
          }else{
            this.loadUClients.tipo = this.selectedType[0];
          }

          this.fillDataAdress(this.localUClients.codigo);
          this.fillDataPhone(this.localUClients.codigo);
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
        });
    },
    valDataUClient() {
      if (this.localUClients.cliente !== "") {
        if (this.regexMail.test(this.localUClients.correo)) {
          this.$confirm.require({
            message: "Estas seguro que desear actualizar este registro?",
            header: "Confirmacion",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-success",
            acceptLabel:"Actualizar",
            accept: () => {
              this.sendDataUClient();
            },
            reject: () => {
              this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Registro sin actualizar", life: 3000, });
            },
          });
        } else {
          this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verifique el formato de correo ingresado", life: 3000, });
        }
      } else {
        this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar campos obligatorios", life: 3000, });
      }
    },
    sendDataUClient(){
      let codigo = this.localUClients.codigo;
      let carnet = this.localUClients.carnet;
      let nombreCliente = this.localUClients.nombre;
      let nDui = this.localUClients.dui;
      let nNit = this.localUClients.nit;
      let nNrc = this.localUClients.nrc;
      var objContrib = this.localUClients.contribuyente;
      var objCategoria = this.localUClients.categoria;
      var objTipo = this.localUClients.tipo;
      var objEstado = this.localUClients.estado;
      let contrib = objContrib.code;
      let categoria = objCategoria.code;
      let tipoCliente = objTipo.codigo;
      let estado = objEstado.code;
      let Correo = this.localUClients.correo;
      this.dataClients.sendDataUClients(carnet.toUpperCase(), nombreCliente, nDui,nNit,nNrc,Correo,'--',contrib, categoria, tipoCliente, estado, codigo)
      .then(response => {
          if (response.status == 200) {
              let valInsr =  response.data[0].estado;
              if(valInsr===1){
                this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Registro actualizado exitosamente", life: 3000, });
                this.closeModalUClients();
                this.refreshUClients();
              }else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no actualizado!", life: 3000, });
              }
          }
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
          }
      });
    },
    addClientPhone(){
      if (this.localUDPhone !== "") {
        let codigo = this.localUClients.codigo;
        let nTelefono = this.localUDPhone;
        this.dataClients.sendDataIClientPhone(codigo, nTelefono)
        .then(response => {
          if (response.status == 200) {
              let valInsr =  response.data[0].estado;
              if(valInsr===1){
                this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Telefono registrado exitosamente", life: 3000, });
                this.fillDataPhone(codigo);
                this.localUDPhone = '';
              }else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no realizado!", life: 3000, });
              }
          }
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
        });
      } else {
        this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar numero telefonico", life: 3000, });
      }
    },
    addClientAddress(){
      if (this.localUDAddress !== "") {
        let codigo = this.localUClients.codigo;
        let nDireccion = this.localUDAddress;
        this.dataClients.sendDataIClientAddress(codigo, nDireccion)
        .then(response => {
          if (response.status == 200) {
              let valInsr =  response.data[0].estado;
              if(valInsr===1){
                this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Direccion registrada exitosamente", life: 3000, });
                this.fillDataAdress(codigo);
                this.localUDAddress = '';
              }else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no realizado!", life: 3000, });
              }
          }
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
        });
      } else {
        this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar campo de direccion", life: 3000, });
      }
    },
    delClientAddress(val){
      this.$confirm.require({
        message: "Estas seguro que desear eliminar esta direccion?",
        header: "Confirmacion",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-success",
        acceptLabel:"Eliminar",
        accept: () => {
          let codigo = this.localUClients.codigo;
          this.dataClients.delClientAddress(val)
          .then(response => {
              if (response.status == 200) {
                  let valInsr =  response.data[0].estado;
                  if(valInsr===1){
                    this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Registro eliminado exitosamente", life: 3000, });
                    this.fillDataAdress(codigo);
                  }else{
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no eliminado!", life: 3000, });
                  }
              }
          })
          .catch((error) => {
              if(error.response.status===401){
                  this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                  this.$store.commit('logout');
              }else if(error.response.status===400){
                  this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
              }else{
                  this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
              }
          });
        },
        reject: () => {
          this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Eliminacion canceladada", life: 3000, });
        },
      });
    },
    delClientPhone(val){
      this.$confirm.require({
        message: "Estas seguro que desear eliminar este numero telefonico?",
        header: "Confirmacion",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-success",
        acceptLabel:"Eliminar",
        accept: () => {
          let codigo = this.localUClients.codigo;
          this.dataClients.delClientPhone(val)
          .then(response => {
              if (response.status == 200) {
                  let valInsr =  response.data[0].estado;
                  if(valInsr===1){
                    this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Registro eliminado exitosamente", life: 3000, });
                    this.fillDataPhone(codigo);
                  }else{
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no eliminado!", life: 3000, });
                  }
              }
          })
          .catch((error) => {
              if(error.response.status===401){
                  this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                  this.$store.commit('logout');
              }else if(error.response.status===400){
                  this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
              }else{
                  this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
              }
          });
        },
        reject: () => {
          this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Eliminacion canceladada", life: 3000, });
        },
      });
    }
  },
  created() {
    this.dataClients = new dataClients();
  },
  mounted(){
      this.loadDataClient();
  }
};
</script>