<template>
<Toast />
<Toast position="bottom-right" group="br" />
<ConfirmDialog></ConfirmDialog>
<ConfirmDialog group="positionDialog"></ConfirmDialog>
    <Panel>
        <template #header>
        <h5>Administracion de Productos</h5>
        </template>
        <template #icons>
        <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-briefcase"></span>
        </button>
        </template>
        <div class="container">
        <br />
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-8 p-lg-8">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText id="searchP" type="text" class="p-text-uppercase" placeholder="Buscar Registros" v-model="stringSearch" v-on:keyup.enter="loadDataProducts" />
                </span>
            </div>
            <div class="p-col-12 p-md-2 p-lg-2">
                <Dropdown v-model="selectedGroup" :options="listGroupItems" optionLabel="descripcion" placeholder="Filtrar pro grupo" @change="filterDataProducts" />
            </div>
            <div class="p-col-12 p-md-2 p-lg-2">
            <Button
                class="p-button-raised p-button-outlined p-button-success"
                label="Nuevo Producto"
                icon="pi pi-briefcase"
                iconPos="left"
                @click="openModalProduct"
            />
            </div>
        </div>
            <DataTable :value="tableProducts" showGridlines responsiveLayout="scroll" :paginator="true" :rows="10" class="p-datatable-sm" :loading="loading">
                <template #loading> Cargando registros, favor espere... </template>
                <Column headerStyle="width: 6em" class="p-text-center">
                <template #header> Opciones </template>
                <template #body="slotProps">
                    <Button 
                        icon="pi pi-times" 
                        class="p-button-raised p-button-danger p-button-sm p-button-outlined"
                        @click="selectRowDProduct(slotProps.data.codigo)"
                    />
                    <Button
                    icon="pi pi-pencil" 
                    class="p-button-raised p-button-Info p-button-sm p-button-outlined" 
                    @click="selectRowUProduct(slotProps.data.codigo)"
                    />
                </template>
                </Column>
                <Column field="codigo_barra" headerStyle="width: 8em" class="p-text-center" header="Codigo" ></Column>
                <Column field="descripcion" headerClass="p-text-center" header="Descripcion" ></Column>
                <Column field="preciou" class="p-text-center" header="Precio">
                    <template #body="slotProps">
                        $ {{Number.parseFloat(slotProps.data.preciou).toFixed(2)}}
                    </template>
                </Column>
                <Column field="grupo_nombre" class="p-text-center" header="Grupo"></Column>
                <Column field="sublinea_nombre" class="p-text-center" header="Sublinea"></Column>
                <Column field="estado" class="p-text-center" header="Estado">
                <template #body="slotProps">
                    <Badge v-if="slotProps.data.estado" value="Activo" severity="success"></Badge>
                    <Badge v-else value="Inactivo" severity="danger"></Badge>
                </template>
                </Column>
                <template #empty>
                <div class="p-text-center">
                    <b>{{ menssageRows }}</b>
                </div>
                </template>
            </DataTable>
        </div>
        <Dialog header="Nuevo Producto" v-model:visible="displayModalNProduct" :style="{ width: '720px' }" :modal="true" >
            <modal-n-products
            @controlModalNProducts="openModalProduct"
            @loadDataNProducts="loadDataProducts"
            ></modal-n-products>
        </Dialog>
        <Dialog header="Actualizacion de datos de Producto" v-model:visible="displayModalUProduct" :style="{ width: '720px' }" :modal="true" >
            <modal-u-products
            @controlModalUProducts="openModalUProduct"
            @loadDataUProducts="loadDataProducts"
            v-bind:UProducts="selectProductCode"
            ></modal-u-products>
        </Dialog>
    </Panel>
</template>
<script>
import store from '../../store';
import dataProducts from '../../service/Products'
import modalNProducts from './components/modalNProducts.vue'
import modalUProducts from './components/modalUProducts.vue'
export default {
    components:{
        'modal-n-products':modalNProducts,
        'modal-u-products':modalUProducts
    },
    data() {
        return {
            moduleUser: store.state.moduleApp,
            loading: false,
            displayModalNProduct: false,
            displayModalUProduct: false,
            selectProductCode : 0,
            stringSearch: "",
            tableProducts: [],
            listGroupItems:[],
            menssageRows: "Lista vacia, favor ingresar parametros de busqueda",
            selectedGroup:''
        };
    },
    methods: {
        async fillDataSelectedGroup(){
        await this.dataProducts.getGroupProducts()
            .then(response => {
                this.listGroupItems = response.data;
                this.selectedGroup = response.data[0];
                this.loadDataProducts();
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
            });
        },
        filterDataProducts(){
            this.loadDataProducts();
        },
        async loadDataProducts() {
            let codeGroup = 0;
            let stringData = this.stringSearch;
            if(this.selectedGroup!=''){
                codeGroup = this.selectedGroup.codigo;
            }
            this.loading = true;
            this.dataProducts.getSearchProductsAll(stringData, codeGroup)
            .then((response) => {
                this.tableProducts = response.data;
                this.loading = false;
            })
            .catch((error) => {
                this.tableProducts = [];
                if (error.response.status === 401) {
                    this.$toast.add({severity: "error",summary: "Error!",detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.",group: "br",life: 3000,});
                    this.$store.commit("logout");
                } else if (error.response.status === 400) {
                    console.info(error.response.data.message);
                    this.loading = false;
                } else {
                    this.$toast.add({severity: "error",summary: "Error!",detail: "Error 500: contacte con el administrador de sistema",group: "br",life: 3000,});
                    this.loading = false;
                }
            });
        },
        openModalProduct() {
        this.displayModalNProduct = (this.displayModalNProduct)?false:true;
        },
        openModalUProduct() {
        this.displayModalUProduct = (this.displayModalUProduct)?false:true;
        },
        selectRowUProduct(value){
            this.selectProductCode = value;
            this.openModalUProduct();
        },
        selectRowDProduct(val){
            this.$confirm.require({
            message: "Esta seguro que desea eliminar este producto?",
            header: "Confirmacion",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-success",
            acceptLabel:"Eliminar",
            accept: () => {
            this.dataProducts.delProduct(val)
            .then(response => {
                if (response.status == 200) {
                    let valDel =  response.data[0].estado;
                    if(valDel===1){
                        this.$toast.add({severity: "success",summary: "Exito!",detail: response.data[0].mensaje ,group: "br", life: 3000,});
                        this.loadDataProducts();
                    }else{
                        this.$toast.add({ severity: "error", summary: "Error!", detail: response.data[0].mensaje,group: "br", life: 3000, });
                    }
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.",group: "br", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message,group: "br", life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500", detail: "contacte con el administrador de sistema",group: "br", life: 3000, });
                }
            });
            },
            reject: () => {
                this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Eliminacion canceladada",group: "br", life: 3000, });
            },
        });
        }
    },
    created() {
        this.dataProducts = new dataProducts();
    },
    mounted() {
        this.fillDataSelectedGroup();
        if (!(this.moduleUser.findIndex((data) => data.vista === "Products") > -1)) { this.$router.push("/");}
    },
};
</script>