<template>
    <div class="p-grid p-mt-2">
        <div class="p-col-12 p-md-10 p-lg-10">
            <span class="p-input-icon-left p-float-label" style="width:100%;">
                <i class="pi pi-search" />
                <InputText type="text" id="txtSearch-v" class="p-text-uppercase" v-model="txtSearchList" style="width:100%;" @keyup.enter="searchPaymentsRegister" />
                <label for="txtSearch-v">Busqueda de pagos registrados por codigo de venta</label>
            </span>
        </div>
        <div class="p-col-12 p-md-2 p-lg-2">
            <div class="p-text-center">
                <Button 
                    label="Buscar" 
                    icon="pi pi-search" 
                    class="p-button-outlined p-button-info" 
                    style="width:250px;" 
                    @click="searchPaymentsRegister"
                />
            </div>
        </div>
    </div>
    <DataTable class="p-datatable-sm" :value="dataValue" showGridlines responsiveLayout="scroll" :paginator="true" :rows="10" :loading="loading" :rowsPerPageOptions="[10,20,50]">
        <template #loading>
            <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
        </template>
        <Column headerStyle="width: 150px; text-align: center" bodyStyle="text-align: center" field="venta" header="Codigo venta" ></Column>
        <Column headerStyle="text-align: center" field="cliente" header="Cliente"></Column>
        <Column class="p-text-center" field="fecha" header="Fecha Venta">
            <template #body="slotProps">
                {{ new Date(slotProps.data.fecha).toLocaleString('es-US') }}
            </template>
        </Column>
        <Column class="p-text-center"  field="email" header="Email"></Column>
        <Column headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center">
            <template #header>
                Detalle
            </template>
            <template #body="slotProps">
                <Button type="button" icon="pi pi-search" class="p-button-info p-button-sm" style="margin-right: .5em" @click="loadDetailsData(slotProps.data.venta, slotProps.data.codigo)"></Button>
            </template>
        </Column>
        <template #footer>
            Total de Resultados: {{dataValue ? dataValue.length : 0 }}
        </template>
    </DataTable>
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
            <h5>Detalle de orden de venta</h5>
        </template>
        <Panel>
            <template #header>
                No. {{dataMdGeneral.venta}}
            </template>
            <DataTable :value="dataMdDetalle">
                <Column headerStyle="width: 150px; text-align: center" bodyStyle="text-align: center" field="codigo" header="codigo" ></Column>
                <Column headerStyle="width: 150px; text-align: center" bodyStyle="text-align: center" field="cantidad" header="cantidad"></Column>
                <Column headerStyle="text-align: center" field="descripcion" header="descripcion"></Column>
                <Column headerStyle="width: 150px; text-align: center" field="precio" class="p-text-center" header="Precio">
                    <template #body="slotProps">
                        $ {{Number.parseFloat(slotProps.data.precio).toFixed(2)}}
                    </template>
                </Column>
            </DataTable>
        </Panel>
        <template #footer>
            <Button label="Cerrar Detalle" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeDetailsData"/>
        </template>
    </Dialog>
</template>

<script>
import dataTableService from '../../../service/OnlinePaymentsLegacy'
export default {
    props:{
        searchValueTable:String
    },
    data(){
        return{
            txtSearchList:'',
            loading: false,
            displayModal: false,
            searchValue:this.searchValueTable,
            dataValueStatic : [],
            dataValue : [],
            dataMdGeneral:{
                venta:'',
                cliente:'',
                total:''
            },
            dataMdDetalle:[]
        }
    },
    methods:{
        loadDataTable(){
            this.dataTableService.getOnlinePaymentsMain(this.searchValue)
            .then(response => {
                this.dataValueStatic = response.data
                this.dataValue = response.data
                this.loading = false;
            })
            .catch((error) => {
                if(error.response.status===401){
                    console.info("Error 401! Contacte con el administrador de sistema");
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    console.info("Error 500! Contacte con el administrador de sistema");
                }
            });
        },
        loadDetailsData(header, code){
            this.displayModal = true;
            this.dataMdGeneral.venta = header;
            this.dataTableService.getOnlinePaymentsDetails(code)
            .then(response => {
                this.dataMdDetalle = response.data
            })
            .catch((error) => {
                if(error.response.status===401){
                    console.info("Error 401! Contacte con el administrador de sistema");
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    console.info("Error 500! Contacte con el administrador de sistema");
                }
            });
        },
        closeDetailsData(){
            this.displayModal = false;
        },
        searchPaymentsRegister(){
			let txtSearch = this.txtSearchList.toUpperCase();
			if(!txtSearch || /^\s*$/.test(txtSearch)){
				this.$toast.add({severity:'error', summary: 'Error!', detail:'Debe de ingresar un parametro de busqueda', group: 'br', life: 3000});
			}else{
                this.dataValue = this.dataValueStatic.filter(obj => obj.venta==txtSearch);
                if(this.dataValue.length>0){
                    this.txtSearch = '';
                }else{
                    this.$toast.add({severity:'error', summary: 'Error!', detail:'No se encontraron registros', group: 'br', life: 3000});
                    this.dataValue = this.dataValueStatic;
                }
			}
		}
    },
    dataTableService: null,
    created() {
        this.dataTableService = new dataTableService();
        this.loadDataTable();
    },
    mounted() {
        
    }
}
</script>