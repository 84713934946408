<template>
  <div class="layout-profile">
    <button
      class="p-link layout-profile-link"
      style="text-transform: uppercase"
      @click="onClick"
    >
      <b>{{ $store.state.uname }}</b>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="showModalConfigSystem">
            <i class="pi pi-fw pi-microsoft"></i>
            <span>Configuracion de sistema</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logOutProfile">
            <i class="pi pi-fw pi-sign-in"></i>
            <span>Cerrar Sesion</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
  <Dialog
    header="Configuracion de sistema"
    v-model:visible="modalConfigSystem"
    :style="{ width: '750px' }"
    :modal="true"
    position="top"
  >
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12">
      <div class="p-col-12 p-md-12">
        <label for="dSucursal" class="p-text-bold">Sucursal de sistema:</label>
          <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-home"></i>
              </span>
              <Dropdown v-model="selectedBranch" :options="dataBranch" optionLabel="descripcion" />
              <Button icon="pi pi-undo" label="Cambiar Sucursal" class="p-button-raised p-button-outlined p-button-help" @click="changeBranchSystem"/>
          </div>
      </div>
    </div>
  </div>
  </Dialog>
</template>
<script>
import store from '../store';
import dataUsers from '../service/User';
import dataCampus from "../service/Campus";
export default {
  emits: ["loadMenuInfo"],
  data() {
    return {
		expanded: false,
    modalConfigSystem:false,
    selectedBranch:[],
    dataBranch:[]
    };
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    logOutProfile() {
      this.$store.commit("logout");
    },
    showModalConfigSystem(){
      this.modalConfigSystem = true;
      this.loadInfoBranchUser();
    },
    loadInfoBranchUser(){
      let userID = store.state.uid;
      let userBranch = store.state.usede;
      console.log(userBranch);
        this.dataUsers.accessBranch(userID)
        .then((response) => {
          this.dataBranch = response.data;

          let index = this.dataBranch.findIndex(data => data.codigo === userBranch);
          this.selectedBranch = (index.length != "")?this.dataBranch[index]:null;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.info("Error 401: Acceso No Autorizado, debe de iniciar sesion.");
            this.$store.commit("logout");
          } else if (error.response.status === 400) {
            console.info(error.response.data.message);
          } else {
            console.info("Error 500: contacte con el administrador de sistema");
          }
        });
    },
    changeBranchSystem(){
      let userSede = this.selectedBranch.codigo;
      let userGroup = store.state.ugroup;
      if(userSede>0){
        this.$store.commit("setUserSede", userSede);
        this.loadInfoCampus(userSede);
        this.loadModules(userGroup, userSede);
      }
    },
    loadModules(codigo, sede) {
      this.dataUsers.accessModelule(codigo, sede)
        .then((response) => {
          if (response.data) {
            this.$store.commit("setModuleApp", response.data);
            this.$emit("loadMenuInfo");
            this.modalConfigSystem = false;
            location.reload()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              console.info(error.response.data.message);
            } else {
              console.info("Error de conexion, sin resultados.");
            }
          } else {
            console.info("Error de conexion, comunicarse con administrador de sistema.");
          }
        });
    },
    loadInfoCampus(codigo){
        this.dataCampus.getCampusID(codigo)
        .then((response) => {
          if (response.data) {
            this.$store.commit("setUserNSede", response.data[0].descripcion);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              console.info(error.response.data.message);
            } else {
              console.info("Error de conexion, sin resultados.");
            }
          } else {
            console.info("Error de conexion, comunicarse con administrador de sistema.");
          }
        });
    }
  },
  created(){
    this.dataUsers = new dataUsers();
    this.dataCampus = new dataCampus();
  },
  mounted() {},
};
</script>

<style scoped>
</style>