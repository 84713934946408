<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Principal</h5>
        </template>
        <div class="p-text-center">
          <bar-sales></bar-sales>
          <!--<img alt="user header" src="http://store.ugb.edu.sv/store.png" />-->
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import barSales from "./components/barSalesData";
export default {
  components: {
    "bar-sales": barSales,
  },
  data() {
    return {
      totalSales: null,
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
