<template>
	<Toast />
    <Toast position="bottom-right" group="br" />
	<div class="p-grid">
		<div class="p-col-12">
			<Panel>
				<template #header>
					<h5>Registros de Pagos en Linea - LEGACY</h5>
				</template>
				<template #icons>
					<button class="p-panel-header-icon p-link p-mr-2">
						<span class="pi pi-list"></span>
					</button>
				</template>
				<data-table-online-payment v-bind:searchValueTable="searchValueTable" ></data-table-online-payment>
			</Panel>
		</div>
	</div>
</template>

<script>
import store from '../../store'
import dataTableOnlinePayment from './components/tableOnlinePayments'; 
export default {
	components:{
		'data-table-online-payment':dataTableOnlinePayment
	},
	data(){
		return{
			moduleUser: store.state.moduleApp,
			txtSearchList:'',
			searchValueTable:'liliana_benitez',
			isLoading:false
		}
	},
	methods:{
		
	},
	mounted(){
		if (!(this.moduleUser.findIndex((data) => data.vista === "OnlinePaymentsLegacy") > -1)) {
			this.$router.push("/");
		}
	}
}
</script>