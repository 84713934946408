<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="content">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <div class="card">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4 p-md-4">
              <label for="cCarnet">Carnet/Codigo</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-id-card"></i>
                </span>
                <InputText
                  id="cCarnet"
                  type="text"
                  maxlength="15"
                  class="p-text-uppercase"
                  v-model="localClients.carnet"
                />
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccTipo">Tipo Cliente</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-folder"></i>
                </span>
                <Dropdown
                    v-model="localClients.tipo"
                    :options="selectedType"
                    optionLabel="nombre"
                  />
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="busqUonline">Exportar</label>
              <Button label="Consultar Uonline" icon="pi pi-external-link" iconPos="right" class="p-button-outlined" @click="searchDataUonline"/>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="cCNombre">Nombre Completo</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-user"></i>
                </span>
                <span class="p-float-label">
                  <InputText
                    id="cCNombre"
                    type="text"
                    maxlength="250"
                    v-model="localClients.cliente"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-12">
              <label for="ccCorreo">Correo Electronico:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-envelope"></i>
                </span>
                <span class="p-float-label">
                  <InputText
                    id="ccCorreo"
                    type="text"
                    :class="['', isEmailValid()]"
                    v-model="localClients.correo"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccDUI">DUI</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask
                    id="ccDUI"
                    mask="99999999-9"
                    placeholder="00000000-0"
                    v-model="localClients.dui"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccNIT">NIT</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask
                    id="ccNIT"
                    mask="9999-999999-999-9"
                    placeholder="0000-000000-000-0"
                    v-model="localClients.nit"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccNRC">NRC</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-wallet"></i>
                </span>
                <span class="p-float-label">
                  <InputMask
                    id="ccNRC"
                    mask="999999-9"
                    placeholder="000000-0"
                    v-model="localClients.nrc"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccTelefono">Telefono</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-phone"></i>
                </span>
                <span class="p-float-label">
                  <InputMask
                    id="cPhone"
                    mask="9999-9999"
                    placeholder="0000-0000"
                    v-model="localClients.telefono"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccContribuyente">Contribuyente</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-bookmark"></i>
                </span>
                <span class="p-float-label">
                  <Dropdown
                    v-model="localClients.contribuyente"
                    :options="selectedTaxpayer"
                    optionLabel="name"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
              <label for="ccCategoria">Categoria</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-bookmark"></i>
                </span>
                <span class="p-float-label">
                  <Dropdown
                    v-model="localClients.categoria"
                    :options="selectedCategory"
                    optionLabel="name"
                  />
                </span>
              </div>
            </div>
            <div class="p-field p-col-12">
              <label for="ccDireccion">Direccion:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-book"></i>
                </span>
                <span class="p-float-label">
                  <Textarea
                    id="address"
                    :autoResize="true"
                    rows="2"
                    maxlength="250"
                    v-model="localClients.direccion"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col p-text-center">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        @click="closeModalClients"
        class="p-button-outlined p-button-danger p-mr-2 p-mb-2"
        :style="{ width: '175px' }"
        autofocus
      />
      <Button
        label="Registrar Cliente"
        icon="pi pi-save"
        @click="valDataClient"
        class="p-button-outlined p-button-success p-mb-2"
        :style="{ width: '175px' }"
      />
    </div>
  </div>
  <Dialog header="Exportar datos" v-model:visible="dModalExportData" :style="{ width: '850px' }" :modal="true" >
    <div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <InputText placeholder="Busqueda por usuario, carnet o nombre" v-model="inputModalExportData" v-on:keyup.enter="readDataExport"/>
          </div>
        </div>
        <div class="p-col-6 p-md-3">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-arrow-right"></i>
            </span>
            <span class="p-float-label">
              <Dropdown  v-model="selectedSearchExport" :options="listOptionSearchExport" optionLabel="Nombre"/>
            </span>
          </div>
        </div>
        <div class="p-col-6 p-md-3">
          <Button label="Consultar" icon="pi pi-search" iconPos="right" class="p-button-outlined" @click="readDataExport"/>
        </div>
      </div>
      <div class="card">
          <DataTable :value="listsExportClients" stripedRows class="p-datatable-sm" :paginator="true" :rows="5" :rowsPerPageOptions="[5,10,20]" :loading="loadingDataExport" responsiveLayout="scroll" >
            <template #loading> Cargando registros, favor espere... </template>
            <Column class="p-text-center" style="width:100px">
              <template #header> Seleccionar </template>
              <template #body="slotProps">
                <Button 
                  icon="pi pi-arrow-circle-down" 
                  class="p-button-raised p-button-success p-button-sm p-button-outlined"
                  @click="selectRowExportClient(slotProps.data)"
                />
              </template>
            </Column>
            <Column field="usuario" header="Usuario" class="p-text-center" style="width:125px" :sortable="true"></Column>
            <Column field="nombre" header="Nombre" style="min-width:200px" :sortable="true"></Column>
            <Column field="correo" header="Correo" class="p-text-center" style="min-width:250px"></Column>
            <Column field="telefono" header="Telefono" class="p-text-center" style="min-width:100px"></Column>
            <template #empty>
              <div class="p-text-center">
                <b>No, se encontraron resultados de busqueda</b>
              </div>
            </template>
          </DataTable>
      </div>
    </div>
  </Dialog>
</template>
<script>
import dataClients from "../../../service/Clients";
export default {
  emits: ["openModalClientsEvent", "refreshTableClients"],
  data() {
    return {
      localClients: {
        codigo: "",
        carnet: "",
        cliente: "",
        dui: "",
        nit: "",
        nrc: "",
        correo: "",
        telefono: "",
        direccion: "",
        tipo:"",
        contribuyente: "",
        categoria: "",
      },
      selectedType:[],
      selectedTaxpayer: [],
      selectedCategory: [],
      selectedSearchExport:[],
      listOptionSearchExport:[
        { "Codigo": 1, "Nombre": "Estudiante" },
        { "Codigo": 2, "Nombre": "Empleado" }
      ],
      listsExportClients:[],
      dModalExportData:false,
      loadingDataExport:false,
      inputModalExportData:'',
      regexMail:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    isEmailValid() {
      return this.localClients.correo == "" ? "": this.regexMail.test(this.localClients.correo)? "": "p-invalid";
    },
    closeModalClients() {
      this.$emit("openModalClientsEvent");
    },
    refreshClients(){
      this.$emit("refreshTableClients");
    },
    fillDataTaxpayer(){
      this.dataClients.selectTaxpayer()
      .then(response => {
            this.selectedTaxpayer = response;
            this.localClients.contribuyente = response[4];
        })
    },
    fillDataCategory(){
      this.dataClients.selectCategory()
      .then(response => {
        this.selectedCategory = response;
        this.localClients.categoria = response[0];
      })
    },
    fillDataSelectType(){
      this.dataClients.getType()
        .then(response => {
            this.selectedType = response.data;
            if(Object.keys(response.data[0]).length>0){
                  this.localClients.tipo = response.data[0];
              }
        })
        .catch((error) => {
            if(error.response.status===401){
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                this.$store.commit('logout');
            }else if(error.response.status===400){
                this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
            }else{
                this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
            }
        });
    },
    valDataClient() {
      if (
        this.localClients.carnet !== "" &&
        this.localClients.cliente !== "" &&
        this.localClients.direccion !== "" &&
        this.localClients.telefono !== ""
      ) {
        if (this.regexMail.test(this.localClients.correo)) {
          this.$confirm.require({
            message: "Estas seguro que desear guardar este registro?",
            header: "Confirmacion",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-success",
            acceptLabel:"Guardar",
            rejectLabel:"Cancelar",
            accept: () => {
              this.sendDataClient();
            },
            reject: () => {
              this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Registro cancelado", life: 3000, });
            },
          });
        } else {
          this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verifique el formato de correo ingresado", life: 3000, });
        }
      } else {
        this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar campos obligatorios", life: 3000, });
      }
    },
    sendDataClient() {
      let carnet = this.localClients.carnet;
      let nombreCliente = this.localClients.cliente;
      let nDui = this.localClients.dui;
      let nNit = this.localClients.nit;
      let nNrc = this.localClients.nrc;
      var objContrib = this.localClients.contribuyente;
      var objCategoria = this.localClients.categoria;
      var objTipo = this.localClients.tipo;
      let contrib = objContrib.code;
      let categoria = objCategoria.code;
      let tipoCliente = objTipo.codigo;
      let Telefono = this.localClients.telefono;
      let Direccion = this.localClients.direccion;
      let Correo = this.localClients.correo;
      this.dataClients.sendDataclients(carnet.toUpperCase(), nombreCliente,nDui,nNit,nNrc,Correo,'--',contrib, categoria, tipoCliente, Telefono, Direccion)
      .then(response => {
          if (response.status == 200) {
              let valInsr =  response.data[0].estado;
              if(valInsr===1){
                this.$toast.add({ severity: "success", summary: "Confirmado", detail: response.data[0].mensaje, life: 3000, });
                this.closeModalClients();
                this.refreshClients();
              }else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: response.data[0].mensaje, life: 3000, });
              }
          }
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
          }
      });
      
    },
    searchDataUonline(){
      this.dModalExportData = true;
      this.selectedSearchExport = this.listOptionSearchExport[0];
    },
    readDataExport(){
      let input = this.inputModalExportData;
      var objOption = this.selectedSearchExport;
      let option = objOption.Codigo;
      if(input !==''){
        this.loadingDataExport = true;
        this.dataClients.exportsDataUonline(option, input)
          .then(response => {
              if (response.status == 200) {
                this.listsExportClients = response.data;
                this.loadingDataExport = false;
              }else{
                this.listsExportClients=[];
              }
          })
          .catch((error) => {
              if(error.response.status===401){
                  this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                  this.$store.commit('logout');
              }else if(error.response.status===400){
                  this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
              }else{
                  this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
              }
          });
      }else{
        this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar campo de busqueda obligatorio", life: 3000, });
      }
    },
    selectRowExportClient(data){
      this.localClients.codigo =  data.id;
      this.localClients.carnet =  data.usuario;
      this.localClients.cliente =  data.nombre;
      this.localClients.dui =  data.n_dui;
      this.localClients.nit = data.n_nit;
      this.localClients.correo = data.correo;
      this.localClients.telefono = data.telefono;
      this.localClients.direccion =  data.direccion;
      this.dModalExportData = false;
      this.inputModalExportData = '';
      this.listsExportClients=[];
    }
  },
  created() {
    this.dataClients = new dataClients();
  },
  mounted(){
    this.fillDataSelectType();
    this.fillDataTaxpayer();
    this.fillDataCategory();
  }
};
</script>