<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <ConfirmDialog></ConfirmDialog>
  <ConfirmDialog group="positionDialog"></ConfirmDialog>
  <Panel>
    <template #header>
      <h5>Administracion de clientes</h5>
    </template>
    <template #icons>
      <button class="p-panel-header-icon p-link p-mr-2">
        <span class="pi pi-users"></span>
      </button>
    </template>
    <div class="container">
      <br />
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-10 p-lg-10">
          <div class="p-inputgroup">
            <InputText
              id="searchC"
              type="text"
              placeholder="Buscar Registros"
              v-model="stringSearch"
              v-on:keyup.enter="loadDataClients"
            />
            <Button
              class="p-button p-button-outlined"
              label="Buscar"
              icon="pi pi-search"
              iconPos="left"
              @click="loadDataClients"
            />
          </div>
        </div>
        <div class="p-col-12 p-md-2 p-lg-2">
          <Button
            class="p-button-raised p-button-outlined p-button-success"
            label="Nuevo Cliente"
            icon="pi pi-user-plus"
            iconPos="left"
            @click="openModalClients"
          />
        </div>
      </div>
      <div>
        <DataTable :value="tableClients" showGridlines responsiveLayout="scroll" :paginator="true" :rows="10" class="p-datatable-sm" :loading="loading">
          <template #loading> Cargando registros, favor espere... </template>
          <Column headerStyle="width: 6em" class="p-text-center">
            <template #header> Opciones </template>
            <template #body="slotProps">
              <Button 
                icon="pi pi-user-minus" 
                class="p-button-raised p-button-danger p-button-sm p-button-outlined"
                @click="selectRowClientD(slotProps.data.codigo)"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-raised p-button-Info p-button-sm p-button-outlined" 
                @click="selectRowClientU(slotProps.data.codigo)"
              />
            </template>
          </Column>
          <Column field="carnet" headerStyle="width: 8em" class="p-text-center" header="Carnet" :sortable="true"></Column>
          <Column field="nombre_cliente" headerClass="p-text-center" header="Cliente" :sortable="true"></Column>
          <Column field="dui" headerClass="p-text-center" header="DUI"></Column>
          <Column field="nit" headerClass="p-text-center" header="NIT"></Column>
          <Column field="correo" headerClass="p-text-center" header="Correo" :sortable="true"></Column>
          <Column field="tipo" class="p-text-center" header="Tipo"></Column>
          <Column field="estado" class="p-text-center" header="Estado">
            <template #body="slotProps">
              <Badge v-if="slotProps.data.estado" value="Activo" severity="success"></Badge>
              <Badge v-else value="Inactivo" severity="danger"></Badge>
            </template>
          </Column>
          <template #empty>
            <div class="p-text-center">
              <b>{{ menssageRows }}</b>
            </div>
          </template>
        </DataTable>
      </div>
      <Dialog header="Nuevo Cliente" v-model:visible="dModaClients" :style="{ width: '720px' }" :modal="true" >
        <modal-n-clients
          @openModalClientsEvent="openModalClients"
          @refreshTableClients="loadDataClients"
        ></modal-n-clients>
      </Dialog>
      <Dialog header="Actualizacion de datos de cliente" v-model:visible="dModaUpdateClients" :style="{ width: '720px' }" :modal="true">
        <modal-u-clients
          @openModalUClientsEvent="openModalUpdateClients"
          @refreshTableUClients="loadDataClients"
          v-bind:UClient="selectUClient"
        ></modal-u-clients>
      </Dialog>
    </div>
  </Panel>
</template>
<script>
import store from '../../store';
import dataClients from "../../service/Clients";
import modalNClients from "../Clients/components/modalNClients";
import modalUClients from "../Clients/components/modalUClients";
export default {
  components: {
    "modal-n-clients": modalNClients,
    "modal-u-clients": modalUClients,
  },
  data() {
    return {
      moduleUser: store.state.moduleApp,
      loading: false,
      dModaClients: false,
      dModaUpdateClients: false,
      menssageRows: "Lista vacia, favor ingresar parametros de busqueda",
      tableClients: [],
      stringSearch: (store.state.usede == 1)?"SM":"US",
      dataSelectClient: "",
    };
  },
  methods: {
    logError(value) {
      this.$toast.add({severity: "error",summary: "Error!",detail: value,group: "br",life: 3000,});
    },
    logSuccess(value) {
      this.$toast.add({severity: "success",summary: "Exito!",detail: value,group: "br",life: 3000,});
    },
    loadDataClients() {
      let stringData = this.stringSearch;
      if (stringData.trim() != "") {
        this.loading = true;
        this.dataClients
          .getSearchClientsAll(stringData)
          .then((response) => {
            this.tableClients = response.data;
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.logError( "Error 401: Acceso No Autorizado, debe de iniciar sesion.");
              this.$store.commit("logout");
            } else if (error.response.status === 400) {
              console.info(error.response.data.message);
              this.loading = false;
            } else {
              this.logError("Error 500: contacte con el administrador de sistema");
              this.loading = false;
            }
          });
      } else {
        var el = document.getElementById("searchC");
        el.focus();
      }
    },
    openModalClients() {
      this.dModaClients = this.dModaClients ? false : true;
    },
    openModalUpdateClients() {
      this.dModaUpdateClients = this.dModaUpdateClients ? false : true;
    },
    selectRowClientU(value) {
      this.selectUClient = value;
      this.openModalUpdateClients();
    },
    selectRowClientD(val){
      this.$confirm.require({
        message: "Esta seguro que desear eliminar este cliente?",
        header: "Confirmacion",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-success",
        acceptLabel:"Eliminar",
        accept: () => {
          this.dataClients.delClient(val)
          .then(response => {
              if (response.status == 200) {
                  let valInsr =  response.data[0].estado;
                  if(valInsr===1){
                    this.logSuccess(response.data[0].mensaje);
                    this.loadDataClients();
                  }else{
                    this.logError(response.data[0].mensaje);
                  }
              }
          })
          .catch((error) => {
              if(error.response.status===401){
                  this.logError( "Error 401: Acceso No Autorizado, debe de iniciar sesion.");
                  this.$store.commit('logout');
              }else if(error.response.status===400){
                   this.logError("Error 400: "+ error.response.data.message);
              }else{
                  this.logError("Error 500: contacte con el administrador de sistema");
              }
          });
        },
        reject: () => {
          this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Eliminacion canceladada", life: 3000, });
        },
      });
    }
  },
  created() {
    this.dataClients = new dataClients();
  },
  mounted() {
    this.loadDataClients();
    if (!(this.moduleUser.findIndex((data) => data.vista === "Clients") > -1)) {
        this.$router.push("/");
    }
  },
};
</script>
