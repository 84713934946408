<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="content">
      <div class="card">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <label for="ppNombre">Nombre del producto</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-book"></i>
                    </span>
                    <InputText id="ppNombre" type="text" maxlength="250" v-model="localIProduct.nombre" autofocus/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ppCodigoBarra">Codigo de barra</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-bars"></i>
                    </span>
                    <InputText id="ppCodigoBarra" type="text" maxlength="100" class="p-text-uppercase" v-model="localIProduct.codigobarra"/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ppPreciou">Precio Unidad</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-dollar"></i>
                    </span>
                    <InputNumber id="ppPreciou" placeholder="00.00"  v-model="localIProduct.preciou" mode="decimal" locale="en-US" :minFractionDigits="2" :maxFractionDigits="5" />
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ppExento">Exento de IVA</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-ticket"></i>
                    </span>
                    <Dropdown id="ppExento" v-model="localIProduct.exento" :options="selectedExempt" optionLabel="name" />
                </div>
            </div>
            <div class="p-field p-col-6 p-md-6">
                <label for="ppGrupo">Grupo de Articulo</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-caret-right"></i>
                    </span>
                    <Dropdown id="ppGrupo" v-model="localIProduct.grupo" :options="selectedGroupItems" optionLabel="descripcion" placeholder="Seleccionar Grupo" />
                </div>
            </div>
            <div class="p-field p-col-6 p-md-6">
                <label for="ppSublinea">Sublinea</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-caret-right"></i>
                    </span>
                    <Dropdown id="ppSublinea" v-model="localIProduct.sublinea" :options="selectedSublineItem" optionLabel="descripcion" placeholder="Seleccionar Sublinea" />
                </div>
            </div>
            
          </div>
      </div>
    <div class="p-grid">
        <div class="p-col-12 p-text-center">
            <Button
            label="Cerrar"
            icon="pi pi-times"
            @click="closeModalNProducts"
            class="p-button-outlined p-button-danger p-mr-2 p-mb-2"
            :style="{ width: '200px' }"
            />
            <Button
            label="Crear producto"
            icon="pi pi-save"
            class="p-button-outlined p-button-success p-mb-2"
            @click="valDataIProducts"
            :style="{ width: '200px' }"
            />
        </div>
    </div>
  </div>
</template>
<script>
import dataProducts from '../../../service/Products'
export default {
    emits: ["controlModalNProducts", "loadDataNProducts"],
    data(){
        return {
            localIProduct:{
                codigo: "",
                nombre: "",
                codigobarra: "",
                preciou: null,
                exento: null,
                grupo: null,
                sublinea: null
            },
            selectedGroupItems:[],
            selectedSublineItem:[],
            selectedExempt:[]
        }
    },
    methods:{
        closeModalNProducts() {
            this.$emit("controlModalNProducts");
        },
        refreshNProducts() {
            this.$emit("loadDataNProducts");
        },
        async fillDataExemptProduct(){
        await this.dataProducts.selectExemptProduct()
        .then(response => {
                this.selectedExempt = response;
                this.localIProduct.exento = response[0];
            })
        },
        async fillDataGroupItems(){
        await this.dataProducts.getGroupProducts()
            .then(response => {
                this.selectedGroupItems = response.data;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
            });
        },
        async fillDataSubline(){
        await this.dataProducts.getSublineProducts()
            .then(response => {
                this.selectedSublineItem = response.data;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
            });
        },
        valDataIProducts(){
            if (
                this.localIProduct.nombre !== "" &&
                this.localIProduct.preciou >0 &&
                this.localIProduct.codigobarra !== ""
            ) {
                this.$confirm.require({
                    message: "Estas seguro que desear guardar este registro?",
                    header: "Confirmacion",
                    icon: "pi pi-info-circle",
                    acceptClass: "p-button-success",
                    acceptLabel:"Guardar",
                    rejectLabel:"Cancelar",
                    accept: () => {
                        this.sendDataproduct();
                    },
                    reject: () => {
                    this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Registro cancelado", life: 3000, });
                    },
                });
            } else {
                this.$toast.add({ severity: "error", summary: "Validacion", detail: "Error!, verificar campos obligatorios", life: 3000, });
            }
        },
        sendDataproduct(){
            let nombre = this.localIProduct.nombre;
            let codigobarra = this.localIProduct.codigobarra;
            let preciou = this.localIProduct.preciou;
            var objExento = this.localIProduct.exento;
            var objGrupo = this.localIProduct.grupo;
            var objSublinea = this.localIProduct.sublinea;
            let exento = objExento.code;
            let grupo = objGrupo.codigo;
            let sublinea = objSublinea.codigo;
            this.dataProducts.sendDataIProduct(nombre, codigobarra.toUpperCase(), grupo,sublinea, exento, JSON.stringify(preciou))
            .then(response => {
                if (response.status == 200) {
                    let valInsr =  response.data[0].estado;
                    if(valInsr===1){
                        this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Registro almacenado exitosamente", life: 3000, });
                        this.closeModalNProducts();
                        this.refreshNProducts();
                    }else{
                        this.$toast.add({ severity: "error", summary: "Error!", detail: response.data[0].mensaje, life: 3000, });
                    }
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
                }
            });
        }
    },
    created(){
        this.dataProducts = new dataProducts();
    },
    mounted(){
        this.fillDataGroupItems();
        this.fillDataSubline();
        this.fillDataExemptProduct();
    }
}
</script>