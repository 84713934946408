<template>
    <AppLogin v-if="!$store.state.isAuthenticated" @loadMenuInfo="loadmenuData"/>
	<div :class="containerClass" @click="onWrapperClick" v-if="$store.state.isAuthenticated">
		<AppTopBar @menu-toggle="onMenuToggle" />
        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" :src="logo" />
                    </router-link>
                </div>
            <AppProfile @loadMenuInfo="loadmenuData" />
            <AppMenu @menuitem-click="onMenuItemClick" ref="componentsAppMenu"/>
            </div>
        </transition>
		<div class="layout-main">
			<router-view />
		</div>
		<AppFooter />
	</div>
</template>
<script>
import { onMounted } from "vue";
import { usePrimeVue } from "primevue/config";
import AppTopBar from './layouts/AppTopbar.vue';
import AppProfile from './layouts/AppProfile.vue';
import AppMenu from './layouts/AppMenu.vue';
import AppFooter from './layouts/AppFooter.vue';
import AppLogin from './views/Login';
export default {
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        'AppLogin':AppLogin,
    },
    setup() {
        const changeToSpanish = () => {
            const primevue = usePrimeVue();
            primevue.config.locale.monthNames = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Deciembre"],
            primevue.config.locale.dayNames= ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
            primevue.config.locale.dayNamesShort= ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            primevue.config.locale.dayNamesMin= ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
            primevue.config.locale.monthNamesShort= ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            primevue.config.locale.today = "Hoy"
        }
        onMounted(() => {
             changeToSpanish();
        })
    },
    data() {
        return {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
        loadmenuData(){
            this.$refs.componentsAppMenu.loadMenu();
            this.$forceUpdate();
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        logo() {
            //return (this.layoutColorMode === 'dark') ? "assets/layout/images/UGB_Store_logo.png" : "assets/layout/images/UGB_Store_logo.png";
            return "assets/layout/images/UGB_Store_logo.png";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }
}
</script>