import axios from 'axios';
export default class OnlinePayments {
     getOnlinePaymentsMain(vendor) {
		return axios({
            method: 'get',
            url: '/Pedidos/lista/'+vendor
        });
	}
    getOnlinePaymentsDetails(code){
        return axios({
            method: 'get',
            url: '/Pedidos/detalle/'+code
        });
    }
}
