<template>
  <div class="p-grid">
    <div class="p-col-12 p-text-center">
        <h1 style="color:#5D0C28">404 ERROR</h1>
        <hr />
        <h3>
            Pagina no encontrada
            <i class="pi pi-search" style="fontSize: 2rem"></i> 
        </h3>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>