<template>
    <Toast />
    <Toast position="bottom-right" group="br" />
    <ConfirmDialog></ConfirmDialog>
    <ConfirmDialog group="positionDialog"></ConfirmDialog>
    <Card>
        <template #content>
            <DataTable :value="dataDetails" showGridlines :loading="loading" responsiveLayout="scroll" class="p-datatable-sm" style="border:1px solid #E0E0E0;">
                <template #loading> Cargando registros, favor espere... </template>
                <Column field="producto" headerClass="p-text-center" header="Producto" headerStyle="background-color:#E0E0E0;"></Column>
                <Column field="cantidad" class="p-text-center" header="Cantidad" style="width:150px" headerStyle="background-color:#E0E0E0;"></Column>
                <Column field="precio_u" class="p-text-center" header="Precio" style="width:150px" headerStyle="background-color:#E0E0E0;">
                    <template #body="slotProps">
                        $ {{Number.parseFloat(slotProps.data.precio_u).toFixed(2)}}
                    </template>
                </Column>
                <template #empty>
                    <div class="p-text-center">
                        <b>No se han encontrado registros.</b>
                    </div>
                </template>
            </DataTable>
            <Divider />
            <div class="p-text-center">
                Total de venta:  <b>$ {{totalSale}}</b>
            </div>
            <Divider />
            <div class="p-fluid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <table class="p-text-center" style="width:100%;border:1px solid #E0E0E0;">
                        <thead style="background-color:#E0E0E0;">
                            <tr class="p-text-bold">
                                <td>Tipo de entrega</td>
                                <td>Direccion</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {{this.dataGeneral.tipo_entrega}}
                                </td>
                                <td>
                                    {{this.dataGeneral.direccion}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="p-d-flex">
                <div class="p-col-12 p-md-12">
                    <label for="valueURL">URL de pago:</label>
                    <div class="p-inputgroup">
                        <InputText class="p-inputtext-sm" id="valueURL" type="text" v-model="stringUrls" disabled/>
                        <Button icon="pi pi-copy" class="p-button-secondary p-button-outlined" @click="copyURL"/>
                    </div>
                </div>
            </div>
            <Divider />
        </template>
        <template #footer>
            <div class="p-text-center">
                <Button v-if="dataGeneral.estado_codigo===2" label="Anular Orden" icon="pi pi-times" @click="saleCanceled" class="p-button-outlined p-button-danger p-mr-2 p-mb-2" :style="{ width: '150px' }"/>
                <Button v-if="dataGeneral.estado_codigo===2" label="Reenviar Correo" icon="pi pi-send" @click="resendEmail" class="p-button-outlined p-button-info p-mr-2 p-mb-2" :style="{ width: '150px' }"/>
                <Button v-if="dataGeneral.estado_codigo===1" label="Facturar Venta" icon="pi pi-print" @click="closeModalDetailsSales" class="p-button-outlined p-button-success p-mr-2 p-mb-2" disabled :style="{ width: '150px' }"/>
                <Button label="Comprobante" icon="pi pi-file-pdf" @click="getModalVoucher" class="p-button-outlined p-button-help p-mr-2 p-mb-2" :style="{ width: '150px' }"/>
            </div>
        </template>
    </Card>
    <Dialog position="top" v-model:visible="displayVoucher" :style="{width: '900px'}" :modal="true">
        <modal-voucher
            v-bind:codigo="vcodigo">
        </modal-voucher>
    </Dialog>
</template>
<script>
import store from '../../../store'
import dataSales from '../../../service/Vents';
import modalVoucher from '../components/modalSalesVoucher'
export default {
    components:{
        'modal-voucher':modalVoucher
    },
    emits: ["controlModalDetailsSales", "updateData"],
    props: {
        vcodigo: Number,
    },
    data(){
		return{
            dataGeneral:[],
            dataDetails:[],
			displayModal:false,
            loading:false,
            stringUrls:null,
            totalSale:0,
            canCopy:false,
            displayVoucher:false
		}
	},
	methods:{
        closeModalDetailsSales(){
            this.$emit("controlModalDetailsSales");
        },
        async fillDataGeneral(){
            await this.dataSales.getRegisteredSalesgeneralID(this.vcodigo)
            .then(response => {
                this.dataGeneral = response.data[0];
                let SystemURL = store.state.systemURL;
                this.stringUrls = SystemURL+'app/VentasEnLinea/correo/'+this.dataGeneral.referencia;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
                this.dataGeneral = [];
            });
        },
        async fillDataDetails(){
            this.loading = true;
            await this.dataSales.getRegisteredSalesDetails(this.vcodigo)
            .then(response => {
                this.loading = false;
                this.dataDetails = response.data;
                this.totalProductList();
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
                this.loading = false;
                this.dataDetails=[];
            });
        },
        totalProductList(){
            let total = 0;
            if (this.dataDetails.length != "") {
                this.dataDetails.forEach(function (obj) { total += (obj.precio_u * obj.cantidad); });
            }
            this.totalSale = Number.parseFloat(total).toFixed(2);
        },
        async copyURL() {
            await navigator.clipboard.writeText(this.stringUrls);
            this.$toast.add({ severity: "info", summary: "Notificacion", detail: "URL copiada", life: 3000, });
        },
        getModalVoucher(){
            this.displayVoucher = (this.displayVoucher)?false:true;
        },
        saleCanceled(){
            this.$confirm.require({
                message: "Esta seguro que desea anular esta venta?",
                header: "Confirmacion",
                icon: "pi pi-info-circle",
                acceptClass: "p-button-success",
                acceptLabel:"Eliminar",
                accept: () => {
                this.dataSales.sendDataSaleCanceled(this.vcodigo)
                .then(response => {
                    if (response.status == 200) {
                        let valCancel =  response.data[0].estado;
                        if(valCancel===1){
                            this.$toast.add({severity: "success",summary: "Exito!",detail: response.data[0].mensaje ,group: "br", life: 3000,});
                            this.$emit("updateData");
                            this.closeModalDetailsSales();
                        }else{
                            this.$toast.add({ severity: "error", summary: "Error!", detail: response.data[0].mensaje,group: "br", life: 3000, });
                        }
                    }
                })
                .catch((error) => {
                    if(error.response.status===401){
                        this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.",group: "br", life: 3000, });
                        this.$store.commit('logout');
                    }else if(error.response.status===400){
                        this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message,group: "br", life: 3000, });
                    }else{
                        this.$toast.add({ severity: "error", summary: "Error 500", detail: "contacte con el administrador de sistema",group: "br", life: 3000, });
                    }
                });
                },
                reject: () => {
                    this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Eliminacion canceladada",group: "br", life: 3000, });
                },
            });
        },
        resendEmail(){
            this.$confirm.require({
                message: "Esta seguro que desea reenviar un correo?",
                header: "Confirmacion",
                icon: "pi pi-info-circle",
                acceptClass: "p-button-success",
                acceptLabel:"Reenviar",
                accept: () => {
                    let codigo = this.vcodigo;
                    this.dataSales.resendEmail(codigo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$toast.add({ severity: "success", summary: "Exito", detail: "Correo reeviando, exitosamente!.",group: "br", life: 3000, });
                        }
                    })
                    .catch((error) => {
                        if(error.response.status===401){
                            this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.",group: "br", life: 3000, });
                            this.$store.commit('logout');
                        }else if(error.response.status===400){
                            this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message,group: "br", life: 3000, });
                        }else{
                            this.$toast.add({ severity: "error", summary: "Error 500", detail: "contacte con el administrador de sistema",group: "br", life: 3000, });
                        }
                    });
                },
                reject: () => {
                    this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Reenvio cancelado",group: "br", life: 3000, });
                },
            });
        }
	},
    created(){
        this.canCopy = !!navigator.clipboard;
        this.dataSales = new dataSales();
    },
    mounted(){
        this.fillDataGeneral();
        this.fillDataDetails();
    }
}
</script>