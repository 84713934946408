<template>
<Panel>
    <template #header>
        <div class="p-text-center">
            <b>Informe por fechas</b>
        </div>
    </template>
    <div class="container">
        <div class="p-grid">
            <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-md-6 p-lg-6">
                        <div class="p-fluid p-grid p-formgrid">
                            <div class="p-field p-col-4 p-md-4">
                                <label for="dateSearchEnd">Sucursal:</label>
                                <Dropdown v-model="selectedStore" :options="listUserStore" optionLabel="descripcion" />
                            </div>
                            <div class="p-field p-col-4 p-md-4">
                                <label for="dateSearchInicial">Fecha Inicial</label>
                                <Calendar id="dateSearchInicial" v-model="dateISearch" :showIcon="true" :maxDate="dateFSearch" dateFormat="yy-mm-dd" />
                            </div>
                            <div class="p-field p-col-4 p-md-4">
                                <label for="dateSearchEnd">Fecha Final</label>
                                <Calendar id="dateSearchEnd" v-model="dateFSearch" :showIcon="true" :minDate="dateISearch" dateFormat="yy-mm-dd"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-field p-col-4 p-md-2 p-lg-2">
                        <label for="dateSearchEnd">Estado:</label>
                        <Dropdown v-model="selectSalesStatus" :options="listSalesStatus" optionLabel="descripcion" />
                    </div>
                    <div class="p-field p-col-4 p-md-2 p-lg-2">
                        <label for="dateSearchEnd">Formato:</label>
                        <Dropdown v-model="selectedFormat" :options="listFormatDocuments" optionLabel="nombre" />
                    </div>
                    <div class="p-col-4 p-md-2 p-lg-2">
                        <Button
                            class="p-button-raised p-button-outlined p-button-info p-mt-4"
                            label="Generar Informe"
                            icon="pi pi-spinner"
                            iconPos="left"
                            @click="generateOptionsReport"
                        />
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <object id="iframePDF" :data="dataPDF" type="application/pdf" width="100%" height="550px">
                    <iframe :src="dataPDF" width="100%" height="550px" style="border: none;">
                        This browser does not support PDFs. Please download the PDF to view it: 
                    </iframe>
                </object>
            </div>
        </div>
    </div>
</Panel>
</template>
<script>
import store from '../../../store';
import dataUser from '../../../service/User';
import dataSalesReport from '../../../service/salesReports'
import dataSales from '../../../service/Vents'

export default {
  components: {},
  data() {
    return {
      dateISearch:null,
      dateFSearch:null,
      listUserStore:[],
      selectedStore:[],
      listSalesStatus:[{  codigo: 0, descripcion: 'Todos', estado: true, fecha_reg:'' }],
      selectSalesStatus:[],
      listFormatDocuments:[
          { "nombre": "PDF", "codigo": "1" },
          { "nombre": "EXCEL", "codigo": "2" },
      ],
      selectedFormat:[],
      dataPDF:null
    };
  },
  methods: {
      fillDataStore(){
        let userID = store.state.uid;
        let userStore = store.state.usede;
        this.dataUser.accessBranch(userID)
        .then((response) => {
            if (response.data) {
                var storeData = response.data;
                this.listUserStore = storeData;
                this.selectedFormat = this.listFormatDocuments[0];
                let istore = storeData.findIndex(data => data.codigo === userStore);
                this.selectedStore = (istore.length != "")?storeData[istore]:null;
                this.selectDefaultDate();
                this.fillDataStatus();
            }
        })
        .catch((error) => {
            if(error.response.status===401){
                console.info("Error 401! Contacte con el administrador de sistema");
                this.$store.commit('logout');
            }else if(error.response.status===400){
                console.info(error.response.data.message);
            }else{
                console.info("Error 500! Contacte con el administrador de sistema");
            }
        });
      },
      fillDataStatus(){
        this.dataSales.getSalesStatus()
        .then((response) => {
            if (response.data) {
                let list = response.data;                
                list.forEach(element => {
                    this.listSalesStatus.push(element);
                });
                this.selectSalesStatus = this.listSalesStatus[0];
            }
        })
        .catch((error) => {
            if(error.response.status===401){
                console.info("Error 401! Contacte con el administrador de sistema");
                this.$store.commit('logout');
            }else if(error.response.status===400){
                console.info(error.response.data.message);
            }else{
                console.info("Error 500! Contacte con el administrador de sistema");
            }
        });
      },
      selectDefaultDate(){
          this.dateFSearch = new Date();
          let todayDate = new Date();
          todayDate.setDate(todayDate.getDate() - 7);
          this.dateISearch = todayDate; 
      },
      generateOptionsReport(){
          let typeStatus = this.selectSalesStatus;
          let option = this.selectedFormat;
          let storeData = this.selectedStore;
          var date_i = this.dateISearch;
          var date_f = this.dateFSearch;
          if(Object.prototype.toString.call(date_i) === '[object Date]'){
              if(Object.prototype.toString.call(date_f) === '[object Date]'){
                  var result_i = date_i.getFullYear() +'-'+ ((date_i.getMonth() + 1) > 9 ? '' : '0') + (date_i.getMonth() + 1) + '-'+ (date_i.getDate() > 9 ? '' : '0') + date_i.getDate();
                  var result_f = date_f.getFullYear() +'-'+ ((date_f.getMonth() + 1) > 9 ? '' : '0') + (date_f.getMonth() + 1) + '-'+ (date_f.getDate() > 9 ? '' : '0') + date_f.getDate();
                    if(option.codigo == '1'){
                      // get reports by date - PDF
                      this.generateReportPDF(storeData.codigo, typeStatus.codigo, result_i, result_f);
                    }
                    if(option.codigo == '2'){
                      // get reports by date - XLS
                      this.generateReportXLS(storeData.codigo, typeStatus.codigo, result_i, result_f);
                    }
            }else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error formato de fecha final es invalido ", life: 3000, });
            }
        }
        else{
            this.$toast.add({ severity: "error", summary: "Error!", detail: "Error formato de fecha inicial es invalido", life: 3000, });
        }
      },
      generateReportPDF(iStore, status,  fechai, fechaf){
          this.dataSalesReport.getSalesReportBydatesPDF(iStore, status, fechai, fechaf)
            .then(response => {
                if(response.data.type=="text/html"){
                    this.dataPDF= "";
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "No se encontraron registros", life: 3000, });
                }else{
                    var newBlob = new Blob([response.data], {type: "application/pdf"})
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, 'ComprobanteVentaPDF.pdf');
                        return;
                    }
                    const data =  (window.URL || window.webkitURL).createObjectURL(newBlob);
                    this.dataPDF = data;
                    if(navigator.userAgent.indexOf("Firefox") !== -1){
                        setTimeout(function(){
                        window.URL.revokeObjectURL(data);
                        }, 100);
                    }
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    console.info("Error 401! Contacte con el administrador de sistema");
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    console.info("Error 500! Contacte con el administrador de sistema");
                }
            });
      },
      generateReportXLS(iStore, status,  fechai, fechaf){
          let fileName = 'Informe_de_ventas_por_fechas_('+fechai+' - '+fechaf+')';
          this.dataSalesReport.getSalesReportBydatesXLS(iStore, status, fechai, fechaf)
            .then(response => {
                if(response.data.type=="text/html"){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "No se encontraron registros", life: 3000, });
                }else{
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click();
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    console.info("Error 401! Contacte con el administrador de sistema");
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    console.info("Error 500! Contacte con el administrador de sistema");
                }
            });
      }
  },
  created() {
    this.dataUser = new dataUser();
    this.dataSalesReport = new dataSalesReport();
    this.dataSales = new dataSales();
  },
  mounted() {
    this.fillDataStore();
  },
};
</script>