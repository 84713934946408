import { createRouter, createWebHashHistory } from 'vue-router';
import Login from './views/Login/';
import Dashboard from './views/Dashboard/';
import OnlinePayments from './views/OnlinePayments/';
import OnlinePaymentsAdmin from './views/OnlinePaymentsAdmin/';
import OnlinePaymentsReports from './views/OnlinePaymentsReports/';
import OnlinePaymentsLegacy from './views/OnlinePaymentsLegacy/';
import Clients from './views/Clients/';
import ClientsReports from './views/ClientsReports/';
import Products from './views/Products/';
import ProductsReports from './views/ProductsReports/';
import SalesDocuments from './views/SalesDocuments/';
import SalesInvoices from './views/SalesInvoices/';
import SystemUsers from './views/SystemUsers/';
import SystemAcess from './views/SystemAcess/';
import SystemCentral from './views/SystemCentral/';
import PageNotFound from './views/Error/'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: true },
  },
  {
    path: '/pago-en-linea',
    name: 'Pagos en linea',
    component: OnlinePayments,
    meta: { requiresAuth: true },
  },
  {
    path:'/pago-en-linea-admin',
    name: 'Admon pagos en linea',
    component: OnlinePaymentsAdmin,
    meta: { requiresAuth: true },
  },
  {
    path:'/pago-en-linea-reporte',
    name: 'Reporte de pagos en liena',
    component: OnlinePaymentsReports,
    meta: { requiresAuth: true },
  },
  {
    path: '/pago-en-linea-legacy',
    name: 'Pagos en Linea LEGACY',
    component: OnlinePaymentsLegacy,
    meta: { requiresAuth: true },
  },
  {
    path: '/clientes',
    name: 'Admon. Clientes',
    component: Clients,
    meta: { requiresAuth: true },
  },
  {
    path: '/clientes-reporte',
    name: 'Clientes reporte',
    component: ClientsReports,
    meta: { requiresAuth: true },
  },
  {
    path: '/productos',
    name: 'Admon. Productos',
    component: Products,
    meta: { requiresAuth: true },
  },
  {
    path: '/productos-reporte',
    name: 'Productos reporte',
    component: ProductsReports,
    meta: { requiresAuth: true },
  },
  {
    path:'/admin-facturacion',
    name:'Config. Facturacion',
    component: SalesInvoices,
    meta: { requiresAuth: true },
  },
  {
    path:'/admin-correlativos',
    name:'Config. Correlativos',
    component: SalesDocuments,
    meta: { requiresAuth: true },
  },
  {
    path:'/config-usuarios',
    name:'Usuarios de sistema',
    component: SystemUsers,
    meta: { requiresAuth: true },
  },
  {
    path:'/config-accesos',
    name:'Accesos de sistema',
    component: SystemAcess,
    meta: { requiresAuth: true },
  },
  {
    path:'/config-central',
    name:'Config. de sistema',
    component: SystemCentral,
    meta: { requiresAuth: true },
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if(requiresAuth){
      next();
      return;
    }else{
      next("/");
    }
    next("/");
});

export default router;
