<template>
  <ConfirmDialog></ConfirmDialog>
  <ConfirmDialog group="positionDialog"></ConfirmDialog>
    <Button 
        label="Crear Correlativo" 
        class="p-button-raised p-button-outlined p-button-info" 
        icon="pi pi-plus-circle" 
        style="width:250px;"
        @click="openModalNSalesDocument"
    />
    <Dialog header="Registro de nuevo documento de ventas" v-model:visible="displayModal" :style="{width: '720px'}" :modal="true">
        <div class="card">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <label for="ddDescripcion">Descripcion del documento</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-tag"></i>
                        </span>
                        <InputText id="ddDescripcion" type="text" maxlength="150" v-model="localIDocument.descripcion" />
                    </div>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <label for="ddTipoDocumento">Tipo de documento</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-ellipsis-v"></i>
                        </span>
                        <Dropdown id="ddTipoDocumento" v-model="localIDocument.tipo" :options="selectedType" optionLabel="nombre"  placeholder="Seleccionar Tipo de documento" />
                    </div>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <label for="ddNumserie">Numero de serie</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            NS
                        </span>
                        <InputText id="ddNumserie" type="text" maxlength="150" v-model="localIDocument.n_serie" class="p-text-uppercase"/>
                    </div>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <label for="ddNumInicial">Num. Inicial</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            NI
                        </span>
                        <InputNumber id="ddNumInicial"  v-model="localIDocument.n_inicial" mode="decimal" :min="0" :max="999999999" maxlength="10"/>
                    </div>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <label for="ddNumFinal">Num. Final</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            NF
                        </span>
                        <InputNumber id="ddNumFinal" v-model="localIDocument.n_final" mode="decimal" :min="0" :max="999999999" maxlength="10" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="p-text-center">
                <Button label="Cancelar" icon="pi pi-times" @click="closeModalNSalesDocument" class="p-button-outlined p-button-danger p-mr-2 p-mb-2" :style="{ width: '150px' }"/>
                <Button label="Registrar" icon="pi pi-check" @click="validateData" class="p-button-outlined p-button-success p-mb-2" :style="{ width: '150px' }"/>
            </div>
        </template>
    </Dialog>
</template>
<script>
import dataVents from '../../../service/Vents';
import datasalesDocuments from '../../../service/SalesDocuments';
export default {
    emits: ["refreshLoadData"],
    data(){
		return{
            localIDocument:{
                descripcion:'',
                tipo:'',
                n_serie:'',
                n_inicial:0,
                n_final:0
            },
            selectedType:[],
			displayModal:false
		}
	},
	methods:{
        openModalNSalesDocument(){
            this.displayModal = true;
        },
        closeModalNSalesDocument(){
            this.displayModal = false;
        },
        refreshLoadDataMain() {
            this.$emit("refreshLoadData");
        },
        async fillDataTypeDocuments(){
        await this.dataVents.getTypeSalesDocuments()
            .then(response => {
                this.selectedType = response.data;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
            });
        },
        validateData(){
            if(this.localIDocument.descripcion !=""  && this.localIDocument.n_serie!="" &&  this.localIDocument.tipo!="" && this.localIDocument.n_inicial > 0 && this.localIDocument.n_final > 0){
                if(this.localIDocument.n_final > this.localIDocument.n_inicial){
                    this.$confirm.require({
                        message: "Estas seguro que desear guardar este registro?",
                        header: "Confirmar",
                        icon: "pi pi-info-circle",
                        acceptClass: "p-button-success",
                        acceptLabel:"Crear Registro",
                        rejectLabel:"Cancelar",
                        accept: () => {
                            this.sendDataISalesDocuments();
                        },
                        reject: () => {
                        this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Actualizacion cancelada", life: 3000, });
                        },
                    });
                }else{
                    this.$toast.add({ severity: "error", summary: "Numero de documento", detail: "verificar numero inicial y final del documento", life: 3000, });
                }
            }else{
                 this.$toast.add({ severity: "error", summary: "Validacion", detail: "verificar los campos obligatorios", life: 3000, });
            }
        },
        sendDataISalesDocuments(){
            let descripcion = this.localIDocument.descripcion;
            let nserie = this.localIDocument.n_serie;
            let ninicial = this.localIDocument.n_inicial;
            let nfinal = this.localIDocument.n_final;
            var objTipo = this.localIDocument.tipo;
            let tipo = objTipo.codigo;
            this.datasalesDocuments.sendDataISalesDocuments(tipo, descripcion, nserie.toUpperCase(), ninicial,nfinal)
            .then(response => {
                if (response.status == 200) {
                    let valInsr =  response.data[0].estado;
                    if(valInsr===1){
                        this.$toast.add({ severity: "success", summary: "Confirmado", detail: "Registro creado exitosamente", life: 3000, });
                        this.closeModalNSalesDocument();
                        this.refreshLoadDataMain();
                    }else{
                        this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no actualizado!", life: 3000, });
                    }
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
                }
            });
        }
	},
    created(){
        this.datasalesDocuments = new datasalesDocuments();
        this.dataVents = new dataVents();
    },
    mounted(){
        this.fillDataTypeDocuments();
    }
}
</script>