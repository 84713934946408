<template>
  <div class="layout-menu-container">
    <AppSubmenu
      v-if="$store.state.isAuthenticated"
      :items="menu"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
    />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
export default {
  //emits:["loadMenuInfo"],
  data() {
    return {
      menu: [],
      menuUpdata: [],
    };
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    async loadMenu() {
      var menuMain = [], menuItems = [];
      var data = this.$store.state.moduleApp;
      data.forEach(function (obj) {
        if (obj.tipo > 0) {
          if (obj.url !== "") {
            menuItems.push({
              module_code: obj.modulo,
              label: obj.nombre,
              icon: obj.icon,
              to: obj.url,
              module_to: obj.tipo
            });
          }
        }
      });

      data.forEach(function (obj) {
        if (obj.tipo == 0) {
          if (obj.url !== "") {
            menuMain.push({
              module_code: obj.modulo,
              label: obj.nombre,
              icon: obj.icon,
              to: obj.url,
            });
          } else {
            var _item = menuItems.filter(data => data.module_to == obj.modulo);
            menuMain.push({
              module_code: obj.modulo,
              label: obj.nombre,
              icon: obj.icon,
              items: _item
            });
          }
        }
      });
      this.menu = menuMain;
      //this.$forceUpdate();
      
    },
  },
  watch(){

  },
  components: {
    AppSubmenu: AppSubmenu,
  },
  created(){
    
  },
  mounted() {
    this.loadMenu();
  },
  
};
</script>
<style scoped></style>