<template>
  <div class="container">
      <Card>
        <template #title >
          <div class="p-text-center"> Correlativo Actual: {{localUDocument.n_actual}} </div>
        </template>
        <template #content>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <label for="ddDescripcion">Descripcion del documento</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-tag"></i>
                    </span>
                    <InputText id="ddDescripcion" type="text" maxlength="150" v-model="localUDocument.descripcion" />
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddTipoDocumento">Tipo de documento</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-ellipsis-v"></i>
                    </span>
                    <Dropdown id="ddTipoDocumento" v-model="localUDocument.tipo_documento" :options="selectedType" optionLabel="nombre"/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddNumserie">Numero de serie</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        NS
                    </span>
                    <InputText id="ddNumserie" type="text" maxlength="150" v-model="localUDocument.n_serie" class="p-text-uppercase"/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddEstadoDocumento">Estado</label>
                <div class="p-inputgroup">
                   <span class="p-inputgroup-addon">
                        <i class="pi pi-ellipsis-v"></i>
                    </span>
                    <Dropdown id="ddEstadoDocumento" v-model="localUDocument.estado" :options="selectedStatus" optionLabel="nombre"/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddNumInicial">Num. Inicial</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        NI
                    </span>
                    <InputNumber id="ddNumInicial"  v-model="localUDocument.n_inicial" mode="decimal" :min="0" :max="999999999" maxlength="10"/>
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddNumFinal">Num. Final</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        NF
                    </span>
                    <InputNumber id="ddNumFinal" v-model="localUDocument.n_final" mode="decimal" :min="0" :max="999999999" maxlength="10" />
                </div>
            </div>
            <div class="p-field p-col-4 p-md-4">
                <label for="ddNumActual">Num. Actual</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        NA
                    </span>
                    <InputNumber id="ddNumActual" v-model="localUDocument.n_imprimir" mode="decimal" :min="0" :max="999999999" maxlength="10" />
                </div>
            </div>
          </div>
        </template>
        <template #footer>
            <span class="p-text-light"><b>Nota:</b> La actualizacion no afectara a los datos de facturas ya registrados</span>
        </template>
    </Card>
      
      <br />
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 p-lg-12 p-text-center">
                <Button
                label="Cancelar"
                icon="pi pi-times"
                @click="closeModalUpdate"
                class="p-button-outlined p-button-danger"
                :style="{ width: '150px' }"
                />
                <Button
                label="Actualizar"
                icon="pi pi-times"
                @click="validateUpdateDocuments"
                class="p-button-outlined p-button-success"
                :style="{ width: '150px' }"
                />
            </div>
        </div>
  </div>
</template>
<script>
import dataVents from '../../../service/Vents';
import dataSalesDocuments from '../../../service/SalesDocuments';
export default {
  emits: ['closeModalUpdateDocuments', 'refresUpdateDocuments'],
  props: {
    dDocumentCode : Number
  },
  data() {
    return {
      dataVents:null,
      dataSalesDocuments:null,
      localUDocument:{
        codigo:0,
        descripcion:'',
        tipo_documento:'',
        n_serie:'',
        n_inicial:0,
        n_final:0,
        n_actual:0,
        n_imprimir:0,
        estado:0
      },
      selectedType:[],
      selectedStatus:[],
      ActualCode: this.dDocumentCode
    };
  },
  methods: {
    closeModalUpdate() {
      this.$emit('closeModalUpdateDocuments');
    },
    refreshLoadDataMain(){
      this.$emit('refresUpdateDocuments');
    },
    async fillDataTypeDocuments(){
      await this.dataVents.getTypeSalesDocuments()
      .then(response => {
        this.selectedType = response.data;
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
          }
      });
    },
    async fillDataStatus(){
      await this.dataSalesDocuments.selectStatusSalesDocuments()
      .then(response => {
        this.selectedStatus = response;
      })
    },
    fillDataDocuments(){
      if(this.ActualCode>0){
        this.fillDataTypeDocuments();
        this.fillDataStatus();
        this.dataSalesDocuments.getSalesDocumentsID(this.ActualCode)
        .then(response =>{
          var getData = response.data[0];
          this.localUDocument.codigo = getData.codigo;
          this.localUDocument.descripcion = getData.descripcion;
          this.localUDocument.n_serie = getData.serie;
          this.localUDocument.n_inicial = getData.n_inicial;
          this.localUDocument.n_final = getData.n_final;
          this.localUDocument.n_actual = getData.n_actual;
          this.localUDocument.n_imprimir = getData.n_actual;
          this.localUDocument.estado = (getData.estado)?this.selectedStatus[1]:this.selectedStatus[0];
          if(getData.tipo_codigo !=""){
              let tDocumento = JSON.stringify(getData.tipo_codigo);
              let iDocumento = this.selectedType.findIndex(data => JSON.stringify(data.codigo) === tDocumento);
              this.localUDocument.tipo_documento = (iDocumento.length != "")?this.selectedType[iDocumento]:null;
          }else{
              this.localUDocument.tipo_documento = null;
          }
        })
        .catch((error) =>{
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400!", detail: error.response.data.message, life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
          }
        });
      }
    },
    validateUpdateDocuments(){
      if(this.localUDocument.descripcion !=""  && this.localUDocument.n_serie!="" &&  this.localUDocument.tipo!="" && this.localUDocument.n_inicial > 0 && this.localUDocument.n_final > 0 && this.localUDocument.n_imprimir > 0){
          if(this.localUDocument.n_final > this.localUDocument.n_inicial){
            if((this.localUDocument.n_imprimir >= this.localUDocument.n_inicial)&& (this.localUDocument.n_imprimir < this.localUDocument.n_final)){
              this.$confirm.require({
                  message: "Estas seguro que desea actualizar este registro?",
                  header: "Confirmar",
                  icon: "pi pi-info-circle",
                  acceptClass: "p-button-success",
                  acceptLabel:"Actualizar",
                  rejectLabel:"Cancelar",
                  accept: () => {
                      this.processUpdateDocuments();
                  },
                  reject: () => {
                  this.$toast.add({ severity: "error", summary: "Cancelado", detail: "Actualizacion cancelada", life: 3000, });
                  },
              });
            }else{
              this.$toast.add({ severity: "error", summary: "Numero de impresion", detail: "verificar numero a imprimir, no esta dentro del rango del inicial y final del documento", life: 3000, });
          }
          }else{
              this.$toast.add({ severity: "error", summary: "Numero de documento", detail: "verificar numero inicial y final del documento", life: 3000, });
          }
      }else{
            this.$toast.add({ severity: "error", summary: "Validacion", detail: "verificar los campos obligatorios", life: 3000, });
      }
    },
    processUpdateDocuments(){
      let codigo = this.localUDocument.codigo;
      let descripcion = this.localUDocument.descripcion;
      let nSerie = this.localUDocument.n_serie;
      let nInicial = this.localUDocument.n_inicial;
      let nFinal = this.localUDocument.n_final;
      let nImprimir = this.localUDocument.n_imprimir;
      var objTipo = this.localUDocument.tipo_documento;
      var objEstado = this.localUDocument.estado;
      let tipo = objTipo.codigo;
      let estado = objEstado.codigo;
      this.dataSalesDocuments.sendDataUSalesDocuments(tipo, descripcion, nSerie.toUpperCase(), nInicial,nFinal, nImprimir, estado, codigo)
      .then(response => {
          if (response.status == 200) {
              let valInsr =  response.data[0].estado;
              if(valInsr===1){
                  this.$toast.add({ severity: "success", summary: "Exito!", detail: "Registro actualizado exitosamente", life: 3000, });
                  this.closeModalUpdate();
                  this.refreshLoadDataMain();
              }else{
                  this.$toast.add({ severity: "error", summary: "Error!", detail: "Registro no actualizado!", life: 3000, });
              }
          }
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message, life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500", detail: "Contacte con el administrador de sistema.", life: 3000, });
          }
      });
    }
  },
  created(){
    this.dataVents = new dataVents();
    this.dataSalesDocuments = new dataSalesDocuments();
  },
  mounted(){
    this.fillDataDocuments();
  }
};
</script>
