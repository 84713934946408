<template>
  <div class="p-grid p-d-flex">
    <div class="p-col-12">
      <div class="p-text-center">
        <Chart type="bar" :data="chartData" :options="chartOptions" />
      </div>
    </div>
  </div>
</template>
<script>
import dataVents from "../../../service/Vents";
export default {
  data() {
    return {
      totalSales: null,
      chartData: { labels: [], datasets: [] },
      chartOptions: null,
    };
  },
  methods: {
    async getData() {
      let date_f = new Date();
      let todayDate = new Date();
      todayDate.setDate(todayDate.getDate() - 15);
      let date_i = todayDate;
      let result_i =
        date_i.getFullYear() + "-" +(date_i.getMonth() + 1 > 9 ? "" : "0") + (date_i.getMonth() + 1) + "-" + (date_i.getDate() > 9 ? "" : "0") + date_i.getDate();
        let result_f = date_f.getFullYear() + "-" + (date_f.getMonth() + 1 > 9 ? "" : "0") + (date_f.getMonth() + 1) + "-" + (date_f.getDate() > 9 ? "" : "0") + date_f.getDate();
        this.dataVents.getRegisteredSales(result_i, result_f)
        .then((response) => {
          this.totalSales = response.data;
          this.fillChart();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.info("Error 401! Contacte con el administrador de sistema");
          } else if (error.response.status === 400) {
            console.info(error.response.data.message);
          } else {
            console.info("Error 500! Contacte con el administrador de sistema");
          }
        });
    },
    fillChart() {
      let dataDates = [], dataChar1 = [], dataChar2 = [];
      var valueSales = this.totalSales;
      valueSales.forEach(function (obj) {
        dataDates.push(obj.fecha_venta);
      });
      var dataArr = new Set(dataDates);
      let resultDates = [...dataArr];
      var barDates = resultDates.reverse();

      barDates.forEach(function (obj) {
        var resultSales = valueSales.filter(
          (sales) => sales.fecha_venta == obj
        );
        let bar1 = 0,
          bar2 = 0;
        resultSales.forEach(function (x) {
          if (x.estado === 1) {
            bar1++;
          }
          bar2++;
        });
        dataChar1.push(bar1);
        dataChar2.push(bar2);
      });
      this.chartData.labels = barDates;
      var barData1 = {
        type: "line",
        label: "Pagos realizados",
        borderColor: "#66BB6A",
        borderWidth: 2,
        fill: false,
        data: dataChar1,
      };
      this.chartData.datasets.push(barData1);
      var barData2 = {
        type: "bar",
        label: "Ordenes registradas",
        backgroundColor: "#75BEF8",
        borderColor: "white",
        borderWidth: 2,
        data: dataChar2,
      };
      this.chartData.datasets.push(barData2);
      this.chartOptions = {
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        plugins: {
          legend: { labels: { color: "#343a3f" } },
        },
        scales: {
          x: {
            ticks: {
              color: "#343a3f",
              beginAtZero: true,
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#343a3f",
              beginAtZero: true,
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      }
    },
  },
  created() {
    this.dataVents = new dataVents();
  },
  mounted() {
    this.getData();
  },
};
</script>
