<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Informes de ventas</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-book"></span>
          </button>
        </template>
        <div>
          <div class="p-grid nested-grid">
            <div class="p-col-12 p-md-12 p-lg-2">
              <Menu :model="items" />
            </div>
            <div class="p-col-12 p-md-12 p-lg-10">
              <repor-by-data v-if="selectReportsArea=='reporByData'"></repor-by-data>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from '../../store';
import reporByData from './components/reportBydates.vue'
export default {
  components: {
    'repor-by-data':reporByData
  },
  data() {
    return {
      moduleUser: store.state.moduleApp,
      selectReportsArea : 'reporByData',
      items: [
				{
            label: 'Informe por fechas',
            icon: 'pi pi-angle-right',
            command: () => {
              this.selectionArea('reporByData');
            }
          },
          /*
          {
            label: 'Seguiente informe',
            icon: 'pi pi-angle-right',
            command: () => {
              this.selectionArea();
            }
          }*/
        ]
    };
  },
  methods: {
    selectionArea(area){
      this.selectReportsArea = area;
      //this.$toast.add({severity:'success', summary:'Updated', detail:'Data Updated', life: 3000});
    }
  },
  created() {
    if (!(this.moduleUser.findIndex((data) => data.vista === "OnlinePaymentsReports") > -1)) {  this.$router.push("/");  }
  },
  mounted() {
  },
};
</script>