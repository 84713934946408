import axios from 'axios';
export default class dataUser {
    async accessModelule(codigo, sede){
        return await axios({
            method: 'get',
            url: '/Usuario/modulos/'+codigo+'/'+sede
        });
    }
    async accessBranch(codigo){
        return await axios({
            method: 'get',
            url: '/Usuario/sedes/'+codigo
        });
    }
    async listUsers(){
        return await axios({
            method:'get',
            url:'/Usuario/lista'
        });
    }
}