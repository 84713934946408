<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Informes</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-book"></span>
          </button>
        </template>
        <div>
          <div class="p-grid nested-grid">
            <div class="p-col-12 p-md-12 p-lg-2">
              <Menu :model="items" />
            </div>
            <div class="p-col-12 p-md-12 p-lg-10">
              <general-report v-if="selectReportsArea=='reportGeneral'"></general-report>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import generalReport from "./components/reportGeneral"
export default {
   components: {
    'general-report':generalReport
  },
  data() {
    return {
      moduleUser: store.state.moduleApp,
      selectReportsArea : 'reportGeneral',
      items: [
        {
          label: 'Listado general',
          icon: 'pi pi-angle-right',
          command: () => { this.selectionArea('reportGeneral'); }
        },
      ]
    };
  },
  methods: {
    selectionArea(area){
      this.selectReportsArea = area;
    }
  },
  created() {
    
  },
  mounted() {
    if (!(this.moduleUser.findIndex((data) => data.vista === "ProductsReports") > -1)) {
      this.$router.push("/");
    }
  },
};
</script>