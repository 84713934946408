<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Administracion de documentos de venta</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-sort-numeric-down-alt"></span>
          </button>
        </template>
        <div class="p-grid p-mt-2">
          <div class="p-col-12 p-md-12 p-lg-12 p-text-right">
            <Toolbar class="p-mb-12">
              <template #right>
                <modal-n-sales-document
                  @refreshLoadData="loadDataSalesDocuments"
                ></modal-n-sales-document>
              </template>
            </Toolbar>
          </div>
          <hr />
        </div>
        <div class="p-grid p-mt-12">
          <div class="card" style="width: 100%">
            <DataTable
              :value="tableSalesDocuments"
              stripedRows
              :loading="loading"
              class="p-datatable-sm"
            >
              <template #loading>
                Cargando registros, favor espere...
              </template>
              <Column headerStyle="width: 6em" class="p-text-center">
                <template #header> Editar </template>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="
                      p-button-raised
                      p-button-warning
                      p-button-sm
                      p-button-outlined
                    "
                    @click="selectRowUDocuments(slotProps.data.codigo)"
                  />
                </template>
              </Column>
              <Column
                field="nombre_documento"
                header="Tipo de documento"
              ></Column>
              <Column field="descripcion" header="Descripcion"></Column>
              <Column
                field="n_serie"
                class="p-text-center"
                header="Num. Serie"
              ></Column>
              <Column
                field="n_inicial"
                class="p-text-center"
                header="Num. Inicial"
              ></Column>
              <Column
                field="n_final"
                class="p-text-center"
                header="Num. Final"
              ></Column>
              <Column
                field="n_actual"
                class="p-text-center"
                header="Num. Actual"
              ></Column>
              <Column field="estado" class="p-text-center" header="Estado">
                <template #body="slotProps">
                  <Badge
                    v-if="slotProps.data.estado"
                    value="Activo"
                    severity="success"
                  ></Badge>
                  <Badge v-else value="Inactivo" severity="danger"></Badge>
                </template>
              </Column>
              <template #empty>
                <div class="p-text-center">
                  <b>No se han encontrado registros.</b>
                </div>
              </template>
            </DataTable>
          </div>
        </div>
      </Panel>
    </div>
  </div>
  <Dialog
    header="Actualizacion de documentos de venta"
    v-model:visible="activeModalUDocument"
    :style="{ width: '750px' }"
    :modal="true"
  >
    <modal-u-sales-document
      @closeModalUpdateDocuments="modalUpdateDocuments"
      @refresUpdateDocuments="loadDataSalesDocuments"
      v-bind:dDocumentCode="selectDocumentCode"
    >
    </modal-u-sales-document>
  </Dialog>
</template>
<script>
import store from "../../store";
import dataSalesDocuments from "../../service/SalesDocuments";
import modalNSalesDocument from "./components/modalNSalesDocuments.vue";
import modalUSalesDocument from "./components/modalUSalesDocuments.vue";
export default {
  components: {
    "modal-n-sales-document": modalNSalesDocument,
    "modal-u-sales-document": modalUSalesDocument,
  },
  data() {
    return {
      moduleUser: store.state.moduleApp,
      tableSalesDocuments: [],
      activeModalUDocument: false,
      selectDocumentCode: 0,
      loading: false,
    };
  },
  methods: {
    loadDataSalesDocuments() {
      this.loading = true;
      this.dataSalesDocuments.getSalesDocuments()
        .then((response) => {
          this.tableSalesDocuments = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$toast.add({severity: "error", summary: "Exito!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", group: "br",life: 3000, });
            this.$store.commit("logout");
          } else if (error.response.status === 400) {
            console.info(error.response.data.message);
            this.loading = false;
          } else {
            this.$toast.add({severity: "error", summary: "Error!", detail: "Error 500: contacte con el administrador de sistema", group: "br", life: 3000, });
            this.loading = false;
          }
        });
    },
    selectRowUDocuments(value) {
      this.selectDocumentCode = value;
      this.modalUpdateDocuments();
    },
    modalUpdateDocuments() {
      this.activeModalUDocument = this.activeModalUDocument ? false : true;
    },
  },
  created() {
    this.dataSalesDocuments = new dataSalesDocuments();
  },
  mounted() {
    this.loadDataSalesDocuments();
    if (!(this.moduleUser.findIndex((data) => data.vista === "SalesDocuments") > -1)) {
      this.$router.push("/");
    }
  },
};
</script>