import axios from "axios";
export default class dataRegBranch {
    async listRegional(){
        return await axios({
            method: 'get',
            url: '/Sistema/regionales'
        });
    }
    async listBranch(){
        return await axios({
            method: 'get',
            url: '/Sedes'
        });
    }
}