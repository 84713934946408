<template>
  <div class="container">
      <br />
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-9 p-md-9 p-lg-9">
            <span class="p-float-label">
                <InputText id="searchC" type="text" v-model="stringSearch" v-on:keyup.enter="loadDataClients" />
                <label for="searchC">Buscar Registro</label>
            </span>
        </div>
        <div class="p-col-3 p-md-3 p-lg-3">
            <Button class="p-button-outlined" label="Realizar Busqueda" icon="pi pi-search" iconPos="right" @click="loadDataClients" />
        </div>
        </div>
        <div>
            <DataTable :value="tableClients" showGridlines responsiveLayout="scroll" :paginator="true" :rows="10" class="p-datatable-sm" :loading="loading">
                <template #loading>
                    Cargando registros, favor espere...
                </template>
                <Column headerStyle="width: 3em" class="p-text-center">
                    <template #header>
                        Seleccionar
                    </template>
                    <template #body="slotProps">
                        <Button 
                        icon="pi pi-check" 
                        @click="selectedClientsData(slotProps.data)"
                        class="p-button-rounded p-button-success p-button-sm p-button-outlined" />
                    </template>
                </Column>
                <Column field="carnet" header="Carnet"></Column>
                <Column field="nombre_cliente" header="Cliente"></Column>
                <Column field="dui" header="DUI"></Column>
                <Column field="correo" header="Correo"></Column>
                <template #empty>
                    <div class="p-text-center"><b>{{menssageRows}}</b></div>
                </template>
            </DataTable>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 p-lg-12 p-text-center">
                <Button
                label="Cancelar"
                icon="pi pi-times"
                @click="closeModalSearchClients"
                class="p-button-outlined p-button-danger"
                :style="{ width: '150px' }"
                autofocus
                />
            </div>
        </div>
  </div>
</template>
<script>
import dataClients from '../../../service/Clients'

export default {
  emits: ['closeSearchModalClients', 'fillDataModalClients'],
  props: {},
  data() {
    return {
        loading:false,
        menssageRows:'Lista vacia, favor ingresar parametros de busqueda',
        tableClients: [],
        stringSearch:'',
    };
  },
  methods: {
    closeModalSearchClients() {
      this.$emit('closeSearchModalClients');
    },
    loadDataClients(){
        let stringData = this.stringSearch;
        if(stringData.trim()!=''){
            this.loading = true;
            this.dataClients.getSearchClients(stringData)   
            .then(response => {
                this.tableClients = response.data;
                this.loading = false;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                    this.menssageRows = 'No se encontraron registros';
                    this.loading = false;
                }
                else{
                    this.logError('Error 500: contacte con el administrador de sistema');
                    this.loading = false;
                }
            })
        }
    },
    selectedClientsData(data){
        this.$emit('fillDataModalClients', data);
    }
  },
  created(){
      this.dataClients = new dataClients();
  }
};
</script>
