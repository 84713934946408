import axios from 'axios';
export default class dataClients {
    async getSearchClients(value) {
        return await axios({
            method: 'get',
            url: '/Clientes/buscar?val=' + value
        });
    }
    async getSearchClientsAll(value) {
        return await axios({
            method: 'get',
            url: '/Clientes/lista?val=' + value
        });
    }
    async getNumberPhone(code) {
        return await axios({
            method: 'get',
            url: 'Clientes/tel/' + code
        });
    }
    async getAddress(code) {
        return await axios({
            method: 'get',
            url: 'Clientes/dir/' + code
        });
    }
    async getType() {
        return await axios({
            method: 'get',
            url: 'Clientes/tipo'
        });
    }
    async getClientsID(code) {
        return await axios({
            method: 'get',
            url: 'Clientes/' + code
        });
    }
    async sendDataclients(carnet, nombreCliente, nDui, nNit, nNrc, correo, correoAlt, contrib, categoria, tipoCliente, telefono, direccion) {
        return await axios({
            method: 'post',
            url: 'Clientes/enviar',
            data: {
                carnet: carnet,
                nombre: nombreCliente,
                dui: nDui,
                nit: nNit,
                nrc: nNrc,
                correo: correo,
                correo_alt: correoAlt,
                contribuyente: contrib,
                categoria: categoria,
                tipo: tipoCliente,
                telefono: telefono,
                direccion: direccion,
                estado: 1,
                codigo: 0
            }
        });
    }
    async sendDataUClients(carnet, nombreCliente, nDui, nNit, nNrc, correo, correoAlt, contrib, categoria, tipoCliente, estado, codigo) {
        return await axios({
            method: 'post',
            url: 'Clientes/enviar',
            data: {
                carnet: carnet,
                nombre: nombreCliente,
                dui: nDui,
                nit: nNit,
                nrc: nNrc,
                correo: correo,
                correo_alt: correoAlt,
                contribuyente: contrib,
                categoria: categoria,
                tipo: tipoCliente,
                telefono: '00-00',
                direccion: 'DIR',
                estado: estado,
                codigo: codigo
            }
        });
    }
    async sendDataIClientPhone(ccliente, ntelefono) {
        return await axios({
            method: 'post',
            url: 'Clientes/telefono',
            data: {
                ccliente: ccliente,
                telefono: ntelefono,
                estado: 1,
                codigo: 0
            }
        });
    }
    async sendDataIClientAddress(ccliente, ndireccion) {
        return await axios({
            method: 'post',
            url: 'Clientes/direccion',
            data: {
                ccliente: ccliente,
                direccion: ndireccion,
                prioridad: 1,
                codigo: 0
            }
        });
    }
    async delClientPhone(codigo) {
        return await axios({
            method: 'delete',
            url: 'Clientes/telefono/'+codigo
        });
    }
    async delClientAddress(codigo) {
        return await axios({
            method: 'delete',
            url: 'Clientes/direccion/'+codigo
        });
    }
    async selectTaxpayer() {
        return await [
            { "name": "Grande", "code": "01" },
            { "name": "Mediano/Pequeño", "code": "02" },
            { "name": "Otros", "code": "03" },
            { "name": "Gobierno", "code": "04" },
            { "name": "Persona natural", "code": "05" },
        ]
    }
    async selectCategory() {
        return await [
            { "name": "Cliente Local", "code": "CL" },
            { "name": "Cliente Extranjero", "code": "CE" },
            { "name": "Proveedor Local", "code": "PL" },
            { "name": "Proveedor Extranjero", "code": "PE" },
            { "name": "Acreedor Local", "code": "AL" },
            { "name": "Acreedor Extranjero", "code": "AE" },
            { "name": "Deudor Local", "code": "DL" },
            { "name": "Deudor Extranjero", "code": "DE" },
            { "name": "Sujeto Excluido", "code": "SU" },
        ]
    }
    async selectStatusClient() {
        return await [
            { "name": "Inactivo", "code": "0" },
            { "name": "Activo", "code": "1" }
        ]
    }
    async delClient(codigo) {
        return await axios({
            method: 'delete',
            url: 'Clientes/'+codigo
        });
    }
    async exportsDataUonline(option, value) {
        return await axios({
            method: 'get',
            url: 'Clientes/buscar_uonline?tipo='+option+'&val='+value
        });
    }
}