<template>
<Toast />
<Toast position="bottom-right" group="br" />
    <Panel>
        <template #header>
            <h5>Administracion de Ordenes de ventas</h5>
        </template>
        <template #icons>
            <button class="p-panel-header-icon p-link p-mr-2">
                <span class="pi pi-tablet"></span>
            </button>
        </template>
        <div class="container">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-6 p-lg-6">
                    <div class="p-fluid p-grid p-formgrid">
                        <div class="p-field p-col-6 p-md-6">
                            <label for="dateSearchInicial">Fecha Inicial</label>
                            <Calendar id="dateSearchInicial" v-model="dateISearch" :showIcon="true" :maxDate="dateFSearch" dateFormat="yy-mm-dd" />
                        </div>
                        <div class="p-field p-col-6 p-md-6">
                            <label for="dateSearchEnd">Fecha Final</label>
                            <Calendar id="dateSearchEnd" v-model="dateFSearch" :showIcon="true" :minDate="dateISearch" dateFormat="yy-mm-dd"/>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3"></div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <Button
                        class="p-button-raised p-button-outlined p-button-info p-mt-4"
                        label="Realizar Busqueda"
                        icon="pi pi-search"
                        @click="searchDataSalesGeneral"
                        iconPos="left"
                    />
                </div>
            </div>
            <DataTable :value="dataTableSales" showGridlines responsiveLayout="scroll" :paginator="true" :rows="10" class="p-datatable-sm" :loading="loading">
                <template #loading> Cargando registros, favor espere... </template>
                <!--<Column field="codigo" headerStyle="width: 8em" class="p-text-center" header="Codigo" ></Column>-->
                <Column field="nfactura" class="p-text-center" header="Num. Factura" ></Column>
                <Column field="referencia" class="p-text-center" header="Referencia"></Column>
                <Column field="cliente" class="p-text-center" header="Cliente"></Column>
                <Column field="correo" class="p-text-center" header="Correo Cliente"></Column>
                <Column field="telefono" class="p-text-center" header="Telefono"></Column>
                <Column field="fecha_reg" class="p-text-center" header="Fecha de Registro">
                    <template #body="slotProps">
                        {{ new Date(slotProps.data.fecha_reg).toLocaleString('es-US') }}
                    </template>
                </Column>
                <Column class="p-text-center" header="Estado">
                <template #body="slotProps">
                    <Badge v-if="slotProps.data.estado==1" :value="slotProps.data.estado_venta" severity="success"></Badge>
                    <Badge v-else-if="slotProps.data.estado==2" :value="slotProps.data.estado_venta" severity="info"></Badge>
                    <Badge v-else-if="slotProps.data.estado==3" :value="slotProps.data.estado_venta" severity="danger"></Badge>
                    <Badge v-else :value="slotProps.data.estado_venta" severity="warning"></Badge>
                </template>
                </Column>
                <Column headerStyle="width: 6em" class="p-text-center">
                <template #header> Detalle </template>
                <template #body="slotProps">
                    <Button
                    icon="pi pi-window-maximize" 
                    class="p-button-raised p-button-Info p-button-sm p-button-outlined"
                    @click="selectRowSalesDetails(slotProps.data.codigo)"
                    />
                </template>
                </Column>
                <template #empty>
                <div class="p-text-center">
                    <b>No, se encontraron registros de ventas</b>
                </div>
                </template>
            </DataTable>
        </div>
    </Panel>
    <Dialog header="Detalle de venta" v-model:visible="displayModalSalesDetails" :style="{width: '850px'}" :modal="true">
        <modal-sales-details
            @controlModalDetailsSales="openModalDetailsSales"
            @updateData = "searchDataSalesGeneral"
            v-bind:vcodigo="selectSalesMainCode"
        ></modal-sales-details>
    </Dialog>
</template>
<script>
import store from "../../store";
import dataVents from '../../service/Vents'
import modalSalesDetails from './components/modalSalesDetails'
export default {
    components:{
        'modal-sales-details':modalSalesDetails,
    },
    data(){
        return{
            moduleUser: store.state.moduleApp,
            loading: false,
            dateISearch:null,
            dateFSearch:null,
            dataTableSales:[],
            selectSalesMainCode:0,
            displayModalSalesDetails:false
        }
    },
    methods:{
        fillDataSales(date_i, date_f){
            this.dataVents.getRegisteredSales(date_i, date_f)
            .then(response => {
                this.dataTableSales = response.data;
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error 401: Acceso No Autorizado, debe de iniciar sesion.", life: 3000, });
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.$toast.add({ severity: "error", summary: "Error 500!", detail: "Contacte con el administrador de sistema", life: 3000, });
                }
            })
        },
        selectRowSalesDetails(value){
            this.selectSalesMainCode = value;
            this.openModalDetailsSales();
        },
        openModalDetailsSales() {
        this.displayModalSalesDetails = (this.displayModalSalesDetails)?false:true;
        },
        fillDateSelectionInput(){
            this.dateFSearch = new Date();
            var todayDate = new Date();
            todayDate.setDate(todayDate.getDate() - 7);
            this.dateISearch = todayDate;
            this.searchDataSalesGeneral();
        },
        searchDataSalesGeneral(){
            var date_i = this.dateISearch;
            var date_f = this.dateFSearch;
            if(Object.prototype.toString.call(date_i) === '[object Date]'){
                if(Object.prototype.toString.call(date_f) === '[object Date]'){
                    var result_i = date_i.getFullYear() +'-'+ ((date_i.getMonth() + 1) > 9 ? '' : '0') + (date_i.getMonth() + 1) + '-'+ (date_i.getDate() > 9 ? '' : '0') + date_i.getDate();
                    var result_f = date_f.getFullYear() +'-'+ ((date_f.getMonth() + 1) > 9 ? '' : '0') + (date_f.getMonth() + 1) + '-'+ (date_f.getDate() > 9 ? '' : '0') + date_f.getDate();
                    this.fillDataSales(result_i, result_f);
                }else{
                    this.$toast.add({ severity: "error", summary: "Error!", detail: "Error formato de fecha final es invalido ", life: 3000, });
                }
            }
            else{
                this.$toast.add({ severity: "error", summary: "Error!", detail: "Error formato de fecha inicial es invalido", life: 3000, });
            }
        }
    },
    created() {
        this.dataVents = new dataVents();
    },
    mounted() {
        this.fillDateSelectionInput();
        if (!(this.moduleUser.findIndex((data) => data.vista === "OnlinePaymentsAdmin") > -1)) {
            this.$router.push("/");
        }
    }
}
</script>