<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Administracion de usuarios</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-id-card"></span>
          </button>
        </template>
        <Card>
            <template #content>
              <DataTable :value="dataUsersTable" class="p-datatable-sm" stripedRows responsiveLayout="scroll">
                  <Column field="codigo" class="p-text-center" header="Codigo"></Column>
                  <Column field="nombre" class="p-text-center" header="Nombre"></Column>
                  <Column field="apellido" class="p-text-center" header="Apellido"></Column>
                  <Column field="grupo_nombre" class="p-text-center" header="Grupo"></Column>
                  <Column field="fecha_reg" class="p-text-center" header="Registro">
                    <template #body="slotProps">
                      {{ convertFromStringToDate(slotProps.data.fecha_reg) }}
                    </template>
                  </Column>
                  <Column field="estado" class="p-text-center" header="Estado">
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.estado" value="Activo" severity="success"></Badge>
                        <Badge v-else value="Inactivo" severity="danger"></Badge>
                    </template>
                  </Column>
              </DataTable>
            </template>
        </Card>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import dataUsers from "../../service/User"
export default {
  components: {},
  data() {
    return {
      moduleUser: store.state.moduleApp,
      dataUsersTable:[]
    };
  },
  methods: {
    fillUsers(){
      this.dataUsers.listUsers()
      .then(response => {
          if (response.status == 200) {
              this.dataUsersTable = response.data;
          }
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.",group: "br", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message,group: "br", life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500", detail: "contacte con el administrador de sistema",group: "br", life: 3000, });
          }
      });
      
    },
    convertFromStringToDate(responseDate) {
      var getDate = new Date(responseDate);
      var result = getDate.getFullYear() +'-'+ ((getDate.getMonth() + 1) > 9 ? '' : '0') + (getDate.getMonth() + 1) + '-'+ (getDate.getDate() > 9 ? '' : '0') + getDate.getDate();
      return result;
    }
  },
  created() {
    this.dataUsers = new dataUsers();
    if (!(this.moduleUser.findIndex((data) => data.vista === "SystemUsers") > -1)) {
      this.$router.push("/");
    }
  },
  mounted() {
    this.fillUsers();
  },
};
</script>