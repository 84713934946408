<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid">
    <div class="p-col-12">
      <Panel>
        <template #header>
          <h5>Control de accesos</h5>
        </template>
        <template #icons>
          <button class="p-panel-header-icon p-link p-mr-2">
            <span class="pi pi-lock"></span>
          </button>
        </template>
          <div>
            <Tree :value="nodes">
                <template #default="slotProps">
                    <b>{{slotProps.node.label}}</b>
                </template>
                <template #url="slotProps">
                    <a :href="slotProps.node.data">{{slotProps.node.label}}</a>
                </template>
            </Tree>
          </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import dataCampus from "../../service/Campus"
export default {
  components: {},
  data() {
    return {
      moduleUser: store.state.moduleApp,
      modulesSystem:[],
      nodes:[]
    };
  },
  methods: {
    fillModules(){
      this.dataCampus.getModules()
      .then(response => {
          if (response.status == 200) {
              this.processModuleNode(response.data);
          }
      })
      .catch((error) => {
          if(error.response.status===401){
              this.$toast.add({ severity: "error", summary: "Error 401", detail: "Acceso No Autorizado, debe de iniciar sesion.",group: "br", life: 3000, });
              this.$store.commit('logout');
          }else if(error.response.status===400){
              this.$toast.add({ severity: "error", summary: "Error 400", detail: error.response.data.message,group: "br", life: 3000, });
          }else{
              this.$toast.add({ severity: "error", summary: "Error 500", detail: "contacte con el administrador de sistema",group: "br", life: 3000, });
          }
      });
      
    },
    processModuleNode(data){
      var menuMain = [], menuItems = [];
      data.forEach(function (obj) {
        if (obj.padre == 0) {
            menuItems.push({
              codigo: obj.codigo,
              label: obj.nombre,
              icon: obj.icon,
              to: obj.url,
              view: obj.vista
            });
        }
      });
      let i = 0, j = 0;
      menuItems.forEach(function (obj) {
        var _item = [], item = [];
        _item = data.filter(data => data.padre == obj.codigo);
        if(_item!=""){
          _item.forEach(function (obj) {
            item.push({
              key: i+'-'+j,
              label: obj.nombre,
              data: obj.nombre,
              icon: obj.icon
              });
              j++;
          });
        }
        menuMain.push({
          key:i,
          //module_code: obj.modulo,
          //label: obj.label.replace(/ /g, ""),
          label: obj.label,
          data: obj.label,
          icon: obj.icon,
          children: item
        });
        i++;
      })
      this.nodes = menuMain;
    }
  },
  created() {
    if (!(this.moduleUser.findIndex((data) => data.vista === "SystemAcess") > -1)) { this.$router.push("/"); }
    this.dataCampus = new dataCampus();
  },
  mounted() {
    this.fillModules();
  },
};
</script>