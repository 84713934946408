import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
import router from "../router";
import axios from "axios";
var ls = new SecureLS({ isCompression: false });

const store = createStore({
    state:{
        isAuthenticated:false,
        uid:'',
        uname:'',
        utoken:'',
        uregional:0,
        ugroup:0,
        usede:0,
        nsede:'',
        moduleApp:[],
        systemURL:''
    },
    mutations:{
        setAuthenticated(state, value){
            state.isAuthenticated = value;
        },
        setUserId(state, value){
            state.uid = value;
        },
        setUsername(state, value){
            state.uname = value;
        },
        setUserGroup(state, value){
            state.ugroup = value;
        },
        setUserRegional(state, value){
            state.uregional = value;
        },
        setUserSede(state, value){
            state.usede = value;
        },
        setUserNSede(state, value){
            state.nsede = value;
        },
        setUserToken(state, value){
            state.utoken = value;
            state.systemURL = 'https://pagos.ugbstore.ugb.edu.sv/';
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ value;
        },
        setModuleApp(state, modules){
            state.moduleApp = modules;
        },
        logout(state){
            router.push('/');
            state.isAuthenticated = false;
            state.uid='';
            state.uname='';
            state.ugroup = 0;
            state.usede = 0;
            state.nsede = '';
            state.uregional = 0;
            state.utoken = '';
            state.moduleApp=[];
            state.systemURL='';
            axios.defaults.headers.common['Authorization'] = '';
        }
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ]
});
export default store;