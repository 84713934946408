import axios from "axios";
export default class dataProducts{
    async getSearchProducts(value){
        return await axios({
            method:'get',
            url:'Productos/seleccionar?val='+value
        });
    }
    async getSearchProductsAll(value, codeGroup){
        return await axios({
            method:'get', 
            url:'Productos/lista?val='+value+'&grupo='+codeGroup
        });
    }
    async getGroupProducts(){
        return await axios({
            method:'get',
            url:'Productos/grupos'
        })
    }
    async getSublineProducts(){
        return await axios({
            method:'get',
            url:'Productos/sublineas'
        })
    }
    async selectExemptProduct() {
        return await [
            { "name": "No Exento", "code": "0" },
            { "name": "Exento", "code": "1" }
        ]
    }
    async selectStatusProduct() {
        return await [
            { "name": "Inactivo", "code": "0" },
            { "name": "Activo", "code": "1" }
        ]
    }
    async getProductsID(code) {
        return await axios({
            method: 'get',
            url: 'Productos/' + code
        });
    }
    async sendDataIProduct(nombre, cod_barra, grupo_cod, sublinea_cod, exento, precio_u) {
        return await axios({
            method: 'post',
            url: 'Productos/enviar',
            data: {
                descripcion: nombre,
                codigo_barra: cod_barra,
                grupo_cod: grupo_cod,
                sublinea_cod: sublinea_cod,
                exento: exento,
                precio: precio_u,
                estado: 1,
                codigo: 0
            }
        });
    }
    async sendDataUProduct(nombre, cod_barra, grupo_cod, sublinea_cod, exento, precio_u, estado, codigo) {
        return await axios({
            method: 'post',
            url: 'Productos/enviar',
            data: {
                descripcion: nombre,
                codigo_barra: cod_barra,
                grupo_cod: grupo_cod,
                sublinea_cod: sublinea_cod,
                exento: exento,
                precio: precio_u,
                estado: estado,
                codigo: codigo
            }
        });
    }
    async delProduct(codigo) {
        return await axios({
            method: 'delete',
            url: 'Productos/'+codigo
        });
    }
}