import axios from "axios";
import store from "../store/index";
export default class dataVents{
    async getDeliveryType(){
        return await axios({
            method:'get',
            url:'Ventas/tipo_entrega'
        });
    }
    async sendDataSale(descripcion,tipoVenta,tipoEntrega,codCliente,codUsuario,Nombre,DUI,NIT,NRC,Telefono,Direccion,Correo,detallePago){
        let uSede = store.state.usede;
        return await axios({
            method:'post',
            url:'Ventas/send',
            data: {
                Descripcion: descripcion,
                TipoVenta: tipoVenta,
                TipoEntrega: tipoEntrega,
                TipoDocumento: 1,
                Sede: uSede,
                Cliente: codCliente,
                Usuario: codUsuario,
                NombreCliente: Nombre,
                DUI: DUI,
                NIT: NIT,
                NRC: NRC,
                Telefono: Telefono,
                Direccion: Direccion,
                Correo: Correo,
                PagoDetalleObj: detallePago
              }
        });
    }
    async getTypeSalesDocuments(){
        return await axios({
            method:'get',
            url:'Ventas/tipo_documentos'
        });
    }
    async getSalesStatus(){
        return await axios({
            method:'get',
            url:'Ventas/estados'
        });
    }
    async getRegisteredSales(date_i, date_f){
        let uId = store.state.uid;
        let uSede = store.state.usede;
        return await axios({
            method:'get',
            url:'Ventas/ventas_registradas?uSede='+uSede+'&fecha_i='+date_i+'&fecha_f='+date_f+'&uId='+uId
        });
    }
    async getRegisteredSalesgeneralID(codigo){
        return await axios({
            method:'get',
            url:'Ventas/general/'+codigo
        });
    }
    async getRegisteredSalesDetails(codigo){
        return await axios({
            method:'get',
            url:'Ventas/detalle/'+codigo
        });
    }
    async getVoucherSalesDetails(codigo){
        return await axios({
            method:'get',
            responseType: "blob",
            url:'Ventas/ComprobanteVentaPDF/'+codigo
        });
    }
    async sendDataSaleCanceled(codigo){
        let uId = store.state.uid;
        return await axios({
            method:'post',
            url:'Ventas/anular',
            data: {
                codigo: codigo,
                usuario: uId
              }
        });
    }
    async resendEmail(codigo){
        return await axios({
            method:'get',
            url:'Ventas/enviarCorreo/'+codigo,
        });
    }
}