<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">
			<span class="layout-topbar-search p-text-bold">
				{{ $store.state.nsede }}
			</span>
			<button class="p-link" @click="logoutAccess">
				<span class="layout-topbar-item-text">Cerrar Sesion</span>
				<span class="layout-topbar-icon pi pi-fw pi-sign-in"></span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		logoutAccess(){
			this.$store.commit('logout');
		}
    }
}
</script>