import axios from "axios";
export default class dataSalesDocuments{
    async getSalesDocuments(){
        return await axios({
            method:'get',
            url:'VentaDocumentos/documentos'
        });
    }
    async getSalesDocumentsID(code){
        return await axios({
            method:'get',
            url:'VentaDocumentos/documentos/'+code
        });
    }
    async selectStatusSalesDocuments() {
        return await [
            { "nombre": "Inactivo", "codigo": "0" },
            { "nombre": "Activo", "codigo": "1" }
        ]
    }
    async sendDataISalesDocuments(documento,descripcion,nserie,n_inicial,n_final){
        return await axios({
            method:'post',
            url:'VentaDocumentos/documento',
            data: {
                tipo_documento: documento,
                descripcion: descripcion,
                n_serie: nserie,
                n_inicial: n_inicial,
                n_final: n_final,
                n_imprimir: n_inicial,
                estado: 1,
                codigo: 0
              }
        });
    }
    async sendDataUSalesDocuments(documento,descripcion,nserie,n_inicial,n_final, n_imprimir, estado, codigo){
        return await axios({
            method:'post',
            url:'VentaDocumentos/documento',
            data: {
                tipo_documento: documento,
                descripcion: descripcion,
                n_serie: nserie,
                n_inicial: n_inicial,
                n_final: n_final,
                n_imprimir: n_imprimir,
                estado: estado,
                codigo: codigo
              }
        });
    }
}