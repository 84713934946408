<template>
    <Toast />
    <Toast position="bottom-right" group="br" />
    <div class="p-grid">
        <div class="p-col-12">
            <Panel>
                <template #header>
					<h5>Orden de ventas</h5>
				</template>
				<template #icons>
					<button class="p-panel-header-icon p-link p-mr-2">
						<span class="pi pi-shopping-cart"></span>
					</button>
				</template>
                <div>
                    <Fieldset legend="Datos de cliente" :toggleable="true" >
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-2 p-lg-2">
                                <label for="cCode">Codigo</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-id-card"></i>
                                    </span>
                                    <AutoComplete 
                                    id="cCode" 
                                    v-model="selectedClients" 
                                    :suggestions="filteredClients" 
                                    @complete="loadFiltersClients($event)" 
                                    @item-select="fillDataClients" 
                                    field="carnet" 
                                    :minLength="2"
                                    autofocus/>
                                    <!--<Button icon="pi pi-user-edit" class="p-button-info p-button-outlined" v-if="clientEditOptions" @click="modalDetailsClients"/>-->
                                    <Button icon="pi pi-search-plus" class="p-button-info p-button-outlined" @click="modalSearchClients"/>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4 p-lg-4">
                                <label for="cClient">Cliente</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-user"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <InputText id="cClient" type="text" v-model="localClients.cliente" :disabled="!clientDefauls"/>
                                    </span>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-2 p-lg-2">
                                <label for="cDui">DUI</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-wallet"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <InputMask id="cDui" mask="99999999-9" placeholder="00000000-0" v-model="localClients.dui" :disabled="!clientDefauls"/>
                                    </span>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-2 p-lg-2">
                                <label for="cNit">NIT</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-wallet"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <InputMask id="cNit" mask="9999-999999-999-9" placeholder="0000-000000-000-0" v-model="localClients.nit" :disabled="!clientDefauls"/>
                                    </span>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-2 p-lg-2">
                                <label for="cNrc">NRC</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-wallet"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <InputMask id="cNrc" mask="999999-9" placeholder="000000-0" v-model="localClients.nrc" :disabled="!clientDefauls"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3 p-lg-3">
                                <label for="cEmail" >Correo</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-envelope"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <InputText id="cEmail" type="text" :class="['', isEmailValid()]" v-model="localClients.correo" :disabled="!clientDefauls"/>
                                    </span>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-3 p-lg-3">
                                <label for="cPhone">Telefono</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-phone"></i>
                                    </span>
                                    <InputText id="cPhone" type="text" v-model="localClients.telefono" v-if="clientDefauls" />
                                    <Dropdown id="cPhone" v-model="localClients.telefono" :options="selectedPhone" optionLabel="telefono" placeholder="Seleccionar Telefono" v-if="!clientDefauls" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6 p-lg-6">
                                <label for="cAddress">Direccion</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-book"></i>
                                    </span>
                                    <InputText id="cPhone" type="text" v-model="localClients.direccion" v-if="clientDefauls" />
                                    <Dropdown id="cAddress" v-model="localClients.direccion" :options="selectedAdress" optionLabel="direccion" placeholder="Seleccionar Direccion" v-if="!clientDefauls"/>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    <br />
                    <Fieldset legend="Detalle de venta" :toggleable="true" :collapsed="true">
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-12 p-md-8 p-lg-8">
                                <div class="p-fluid p-grid">
                                    <div class="p-field p-col-12 p-md-6 p-lg-6">
                                        <label for="pProducto">Busqueda de productos</label>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-search-plus"></i>
                                            </span>
                                            <span class="p-float-label">
                                                <AutoComplete 
                                                id="pProductos" 
                                                v-model="selectedProducts" 
                                                :suggestions="filteredProducts" 
                                                @complete="loadFiltersProducts($event)" 
                                                @item-select="fillDataProducts" 
                                                field="descripcion" 
                                                :minLength="2"/>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2 p-lg-2 p-text-center">
                                        <label for="pCantidad">Cantidad</label>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputNumber id="pCantidad" 
                                                showButtons buttonLayout="horizontal" :step="1"
                                                decrementButtonClass="p-button-danger p-button-outlined" 
                                                incrementButtonClass="p-button-success p-button-outlined" 
                                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
                                                mode="decimal" :min="0" :max="100"
                                                v-model="localProducts.cantidad"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2 p-lg-2 p-text-center">
                                        <label for="pPrecioU">Precio Unidad</label>
                                        <div class="p-inputgroup">
                                            <InputNumber 
                                            id="pPrecioU"  
                                            mode="currency" 
                                            currency="USD" 
                                            locale="en-US" 
                                            :minFractionDigits="2"
                                            v-model="localProducts.preciou" 
                                            disabled />
                                            <Button icon="pi pi-pencil" class="p-button-info p-button-outlined" v-show="priceEditOptions" @click="modalUpdateprices"/>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2 p-lg-2 p-text-center">
                                        <Button label="Agregar" icon="pi pi-plus" iconPos="left" class="p-button-info p-button-raised p-button-outlined p-mt-4" @click="addProductsList" />
                                    </div>
                                </div>
                                <DataTable :value="listProducts" responsiveLayout="scroll" class="p-datatable-sm">
                                    <Column headerStyle="width: 3em" class="p-text-center">
                                        <template #header>
                                            <i class="pi pi-chevron-down"></i>
                                        </template>
                                        <template #body="slotProps">
                                            <Button 
                                            icon="pi pi-times" 
                                            @click="deleteProductsList(slotProps.data)"
                                            class="p-button-rounded p-button-danger p-button-outlined p-button-sm" />
                                        </template>
                                    </Column>
                                    <Column field="descripcion" header="Descripcion"></Column>
                                    <Column header="Precio Unidad"  class="p-text-center">
                                        <template #body="slotProps">
                                            $ {{Number.parseFloat(slotProps.data.preciou).toFixed(2)}}
                                        </template>
                                    </Column>
                                    <Column header="Cantidad" class="p-text-center">
                                        <template #body="slotProps">
                                            <span >{{slotProps.data.cantidad}}</span>
                                        </template>
                                    </Column>
                                    <Column header="Precio Total" class="p-text-center">
                                        <template #body="slotProps">
                                            <span >$ {{Number.parseFloat(slotProps.data.preciou * slotProps.data.cantidad).toFixed(2)}}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <div class="p-field p-col-12 p-md-4 p-lg-4">
                                <Card class="p-text-center">
                                    <template #title>
                                        TOTAL VENTA $ {{totalSale}}
                                    </template>
                                    <template #content>
                                        
                                    </template>
                                    <template #footer>
                                        <Button 
                                        icon="pi pi-check" 
                                        label="Realizar venta" 
                                        class="p-button-success" 
                                        iconPos="right"
                                        :disabled="needValidate"
                                        @click="prepareProcessData"/>
                                    </template>
                                </Card>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </Panel>
        </div>
    </div>
    <Dialog header="Confirmacion" v-model:visible="displayConfirmation" :style="{width: '400px'}" :modal="true" class="p-text-center">
        <div class="confirmation-content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12 p-lg-12">
                    <label for="cDelivery">Tipo de entrega:</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-map"></i>
                        </span>
                        <Dropdown id="cDelivery" v-model="deliveryType" :options="selectedDelivery" optionLabel="nombre" placeholder="Seleccionar Entrega" @change="selectedOptionDelivery"/>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-12 p-lg-12">
                    <label for="cNote">Nota:</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-book"></i>
                        </span>
                        <Textarea id="cNote" v-model="saleNote" :autoResize="true" rows="2" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="p-grid">
                <div class="p-col p-text-center">
                    <Button label="Cancelar" icon="pi pi-times" @click="closeConfirmation" class="p-button-outlined p-button-danger" :style="{width: '150px'}"/>
                    <Button label="Registrar Venta" icon="pi pi-send" @click="validConfirmation" class="p-button-outlined p-button-success" :style="{width: '150px'}" autofocus />
                </div>
            </div>
        </template>
    </Dialog>
    <Dialog header="Busqueda de Clientes" v-model:visible="activeModalSearchClients" :style="{width: '50vw'}" :modal="true">
        <search-modal-clients
            ref="searchClientsComponent"
            @closeSearchModalClients="modalSearchClients"
            @fillDataModalClients="fillDataClientsOfModal"
        ></search-modal-clients>
    </Dialog>
    <Dialog header="Detalle de Cliente" v-model:visible="activeModalDetailsClient" :style="{width: '50vw'}" :modal="true">
        <detail-modal-clients
        @closeDetailsModalClients ="modalDetailsClients"
        >
        </detail-modal-clients>
    </Dialog>
    <Dialog header="Actualizacion de precio" v-model:visible="activeModalUpdatePrice" :style="{width: '650px'}" :modal="true">
        <update-modal-price
        @closeModalUpdateprice ="modalUpdateprices"
        @updateLocalProductPrice = "updateLocalProductPrice"
        v-bind:pPreciou="localProducts.preciou"
        v-bind:pDescripcion="localProducts.descripcion"
        >
        </update-modal-price>
    </Dialog>
</template>
<script>
import store from "../../store";
import dataClients from '../../service/Clients';
import dataProducts from '../../service/Products';
import dataVents from '../../service/Vents';
import searchModalclients from './components/modalSearchClients.vue';
import detailModalClients from './components/modalDetailsClients.vue';
import updatePrice from './components/modalUpdatePrice.vue';
export default {
    components:{
        'search-modal-clients': searchModalclients,
        'detail-modal-clients':detailModalClients,
        'update-modal-price':updatePrice
    },
    data(){
        return{
            moduleUser: store.state.moduleApp,
            selectedClients:[],
            selectedProducts:[],
            filteredClients: null,
            filteredProducts: null,
            selectedPhone:[],
            selectedAdress:[],
            selectedDelivery:[],
            clientDefauls:false,
            clientEditOptions:false,
            priceEditOptions:false,
            localClients:{
                codigo:'',
                carnet:'',
                cliente:'',
                dui:'',
                nit:'',
                nrc:'',
                correo:'',
                telefono:'',
                direccion:''
            },
            localProducts:{
                codigo:null,
                descripcion:null,
                es_exento:null,
                cantidad:null,
                preciou:null
            },
            listProducts:[],
            totalSale:0,
            deliveryType:null,
            saleNote:null,
            displayConfirmation: false,
            activeModalSearchClients:false,
            activeModalDetailsClient:false,
            activeModalUpdatePrice: false,
            needValidate:true,
            titleModalC:'',
            regexMail : /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    methods:{
        loadFiltersClients(event) {
            setTimeout( async() => {
                if (!event.query.trim().length) {
                    this.filteredClients = null;
                }
                else {
                    this.dataClients.getSearchClients(event.query.trim())
                    .then(response => {
                        this.filteredClients = response.data;
                    })
                    .catch((error) => {
                        if(error.response.status===401){
                            this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                            this.$store.commit('logout');
                        }else if (error.response.status === 400) {
                            console.info(error.response.data.message);
                        }else{
                            this.logError('Error 500: contacte con el administrador de sistema');
                        }
                    })
                }
            }, 250);
        },
        loadFiltersProducts(event) {
            setTimeout( async() => {
                if (!event.query.trim().length) {
                    this.filteredProducts = null;
                }
                else {
                    this.dataProducts.getSearchProducts(escape(event.query.trim()))
                    .then(response => {
                        this.filteredProducts = response.data;
                    })
                    .catch((error) => {
                        if(error.response.status===401){
                            this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                            this.$store.commit('logout');
                        }
                        else if (error.response.status === 400) {
                            console.info(error.response.data.message);
                        }else{
                            this.logError('Error 500: contacte con el administrador de sistema');
                        }
                    })
                }
            }, 250);
        },
        fillDataClients(){
            let data = this.selectedClients;
            if(Object.keys(data).length>0){
                this.localClients.codigo = data.codigo;
                this.localClients.carnet = data.carnet;
                this.localClients.cliente = data.nombre_cliente;
                this.localClients.dui = data.dui;
                this.localClients.nit = data.nit;
                this.localClients.nrc = data.nrc;
                this.localClients.correo = data.correo;
                this.localClients.telefono = '';
                this.localClients.direccion = '';
                if(data.codigo==1){
                    this.clientDefauls = true;
                    this.selectedPhone = [];
                    this.selectedAdress = [];
                    this.clientEditOptions = false;
                }else{
                    this.clientDefauls = false;
                    this.fillSelectNumberPhone(data.codigo);
                    this.fillSelectAddress(data.codigo);
                    this.clientEditOptions = true;
                }
                this.needValidate = (this.listProducts.length != "")?false:true;
            }
        },
        fillSelectNumberPhone(codigo){
            this.dataClients.getNumberPhone(codigo)
            .then(response => {
                this.selectedPhone = response.data;
                if(Object.keys(response.data[0]).length>0){
                    this.localClients.telefono = response.data[0];
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.logError('Error 500: contacte con el administrador de sistema');
                }
            });
        },
        fillSelectAddress(codigo){
            this.dataClients.getAddress(codigo)
            .then(response => {
                this.selectedAdress = response.data;
                if(Object.keys(response.data[0]).length>0){
                    this.localClients.direccion = response.data[0];
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.logError('Error 500: contacte con el administrador de sistema');
                }
            });
        },
        fillDataProducts(){
            let data = this.selectedProducts;
            if(Object.keys(data).length>0){
                let preciou_ = Number.parseFloat(data.preciou).toFixed(2);
                this.localProducts.codigo = data.codigo;
                this.localProducts.descripcion = data.descripcion;
                this.localProducts.es_exento = data.es_exento;
                this.localProducts.cantidad = 1;
                this.localProducts.preciou = Number.parseFloat(preciou_);
                this.localProducts.u_precio = 0;
                this.priceEditOptions = true;
            }
        },
        loadDataDeliveryType(){
            this.dataVents.getDeliveryType()
            .then(response => {
                this.selectedDelivery = response.data;
                this.deliveryType = response.data[0];
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    console.info(error.response.data.message);
                }else{
                    this.logError('Error 500: contacte con el administrador de sistema');
                }
            });
        },
        addProductsList(){
            var data = this.localProducts;
            let validateProducts = 0;
            if(Object.keys(data).length>0){
                this.listProducts.forEach(function (obj) {
                    validateProducts += (obj.codigo === data.codigo) ? 1 : 0;
                });
                if(validateProducts>0){
                    this.logError("El producto, ya se encuentra en el listado de venta");
                }else{
                    if(data.preciou > 0 && data.cantidad>0){
                        this.listProducts.push(data);
                        this.selectedProducts=null;
                        this.priceEditOptions = false;
                        this.localProducts = [];
                        this.filteredProducts = null;
                        this.totalProductList();
                    }
                }
            }
        },
        deleteProductsList(objSelected){
            let index = this.listProducts.findIndex(data => data === objSelected);
            this.listProducts.splice(index, 1);
            this.totalProductList();
        },
        totalProductList(){
            let total = 0;
            if (this.listProducts.length != "") {
                this.listProducts.forEach(function (obj) { total += (obj.preciou * obj.cantidad); });
                this.needValidate = (this.localClients.codigo!='')?false:true;
                
            }
            else {
                total = 0;
                this.needValidate = true;
            }
            this.totalSale = Number.parseFloat(total).toFixed(2);
        },
        logError(value){
            this.$toast.add({severity: 'error', summary: 'Error!', detail: value, group: 'br', life: 3000});
        },
        logSuccess(value){
            this.$toast.add({severity: 'success', summary: 'Exito!', detail: value, group: 'br', life: 3000});
        },
        closeConfirmation(){
            this.displayConfirmation = false;
        },
        modalSearchClients(){
            this.activeModalSearchClients = (this.activeModalSearchClients)?false:true;
        },
        modalDetailsClients(){
            this.activeModalDetailsClient = (this.activeModalDetailsClient)?false:true;
        },
        modalUpdateprices(){
            this.activeModalUpdatePrice = (this.activeModalUpdatePrice)?false:true;
        },
        updateLocalProductPrice(value){
            this.localProducts.preciou = Number.parseFloat(value);
            this.localProducts.u_precio = 1;
        },
        prepareProcessData(){
            if(this.localClients.codigo!==''){
                if(this.regexMail.test(this.localClients.correo)){
                    this.displayConfirmation = true;
                }else{
                    this.logError('Error!, verifique el formato de correo ingresado');
                }
            }else{
                 this.logError('Error!, Debe de seleccionar un cliente');
            }
            
            
        },
        isEmailValid(){
            return (this.localClients.correo == "")? "" : (this.regexMail.test(this.localClients.correo)) ? '' : 'p-invalid';
        },
        validConfirmation(){
            var objProductos = [];
            let descripcion = this.saleNote;
            let tipoVenta = 1;
            let objTipoEntrega = this.deliveryType;
            let tipoEntrega = objTipoEntrega.codigo;
            let cliente = this.localClients.codigo;
            let usuario = this.$store.state.uid;
            let Nombre = this.localClients.cliente;
            let DUI = this.localClients.dui;
            let NIT = this.localClients.nit;
            let NRC = this.localClients.nrc;
            let Telefono = '';
            let Direccion = '';
            let Correo = this.localClients.correo;
            this.listProducts.forEach(function (obj) {
                objProductos.push({
                    'Codigo': obj.codigo,
                    'Nombre': obj.descripcion,
                    'Cantidad': obj.cantidad,
                    'PrecioUnidad': obj.preciou,
                    'PrecioActualizado': obj.u_precio
                });
            });
            if(this.clientDefauls){
                Telefono = this.localClients.telefono;
                Direccion = this.localClients.direccion;
            }else{
                var objTelefono = this.localClients.telefono;
                var objDireccion = this.localClients.direccion;
                Telefono = objTelefono.telefono;
                Direccion = objDireccion.direccion;
            }
            this.dataVents.sendDataSale(descripcion,tipoVenta,tipoEntrega,cliente,usuario,Nombre,DUI,NIT,NRC,Telefono,Direccion,Correo,objProductos)
            .then(response => {
                if (response.status == 200) {
                    this.logSuccess('Venta Registrada Exitosamente!');
                    this.resetPage();
                }
            })
            .catch((error) => {
                if(error.response.status===401){
                    this.logError('Error 401: Acceso No Autorizado, debe de iniciar sesion.');
                    this.$store.commit('logout');
                }else if(error.response.status===400){
                    this.logError(error.response.data.message);
                }else{
                    this.logError('Error 500: contacte con el administrador de sistema');
                }
            });
        },
        fillDataClientsOfModal(data){
            if(Object.keys(data).length>0){
                this.selectedClients = data;
                this.localClients.codigo = data.codigo;
                this.localClients.carnet = data.carnet;
                this.localClients.cliente = data.nombre_cliente;
                this.localClients.dui = data.dui;
                this.localClients.nit = data.nit;
                this.localClients.nrc = data.nrc;
                this.localClients.correo = data.correo;
                this.localClients.telefono = '';
                this.localClients.direccion = '';
                if(data.codigo==1){
                    this.clientDefauls = true;
                    this.selectedPhone = [];
                    this.selectedAdress = [];
                    this.clientEditOptions = false;
                }else{
                    this.clientDefauls = false;
                    this.fillSelectNumberPhone(data.codigo);
                    this.fillSelectAddress(data.codigo);
                    this.clientEditOptions = true;
                }
                this.modalSearchClients();
            }
        },
        selectedOptionDelivery(){
            let option = this.deliveryType;
            if(option.codigo==2){
                if(this.clientDefauls){
                    this.saleNote = this.localClients.direccion;
                }else{
                    let objDireccion = this.localClients.direccion;
                    this.saleNote = objDireccion.direccion;
                }
            }else{
                this.saleNote = '';
            }
        },
        resetPage(){
            this.selectedClients=[];
            this.selectedProducts=[];
            this.filteredClients= null;
            this.filteredProducts= null;
            this.selectedPhone=[];
            this.selectedAdress=[];
            this.clientDefauls =false;
            this.localClients ={
                    codigo:'',
                    carnet:'',
                    cliente:'',
                    dui:'',
                    nit:'',
                    nrc:'',
                    correo:'',
                    telefono:'',
                    direccion:''
                };
            this.localProducts={
                    codigo:null,
                    descripcion:null,
                    es_exento:null,
                    cantidad:null,
                    preciou:null
                };
            this.listProducts=[];
            this.totalSale=0;
            this.saleNote=null;
            this.displayConfirmation = false;
            this.needValidate=true;
        }
    },
    created() {
        this.dataClients = new dataClients();
        this.dataProducts = new dataProducts();
        this.dataVents = new dataVents();
    },
    mounted(){
        this.loadDataDeliveryType();
        if (!(this.moduleUser.findIndex((data) => data.vista === "OnlinePayments") > -1)) {
            this.$router.push("/");
        }
    }
}
</script>
<style lang="scss" scoped>
.p-fieldset p {
    line-height: 1.5;
    margin: 0;
}
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>