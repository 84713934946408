<template>
  <div class="container">
      <Card>
        <template #title >
          <div class="p-text-center"> {{descripcion}}</div>
        </template>
        <template #content>
            <div class="p-grid">
                <div class="p-col-12 p-text-center">
                    <h4>Precio actual: <b>$ {{Number.parseFloat(ActualPrice).toFixed(2)}} </b></h4>
                </div>
            </div>
            <div class="p-field p-col-6 p-offset-3 p-text-center">
                <label for="ppPreciou">Precio sugerido:</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-dollar"></i>
                    </span>
                    <InputNumber id="ppPreciou" placeholder="00.00"  v-model="UpdatePreciou" mode="decimal" locale="en-US" :minFractionDigits="2" :maxFractionDigits="5" />
                </div>
            </div>
        </template>
        <template #footer>
            <span class="p-text-light"><b>Nota:</b> La actualizacion de precio se registrara temporalmente para esta venta, y no afectara al precio original del producto</span>
        </template>
    </Card>
      
      <br />
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 p-lg-12 p-text-center">
                <Button
                label="Cancelar"
                icon="pi pi-times"
                @click="closeModalUpdateprice"
                class="p-button-outlined p-button-danger"
                :style="{ width: '200px' }"
                />
                <Button
                label="Actualizar Precio"
                icon="pi pi-check"
                @click="processUpdateprice"
                class="p-button-outlined p-button-success"
                :style="{ width: '200px' }"
                />
            </div>
        </div>
  </div>
</template>
<script>
export default {
  emits: ['closeModalUpdateprice', 'updateLocalProductPrice'],
  props: {
    pPreciou: Number,
    pDescripcion: String,
  },
  data() {
    return {
        loading:false,
        descripcion : this.pDescripcion,
        ActualPrice: this.pPreciou,
        UpdatePreciou:0
    };
  },
  methods: {
    closeModalUpdateprice() {
      this.$emit('closeModalUpdateprice');
    },
    processUpdateprice(){
        if(this.UpdatePreciou>0){
            this.$emit('updateLocalProductPrice', this.UpdatePreciou);
            this.$emit('closeModalUpdateprice');
        }
    }
  }
};
</script>
