<template>
  <Toast />
  <Toast position="bottom-right" group="br" />
  <div class="p-grid p-ai-center vertical-container">
    <div class="p-col-12 p-md-6 p-md-offset-3">
      <form class="form p-shadow-5">
        <div class="p-grid">
          <div class="p-col p-text-center">
            <img alt="Logo" src="assets/layout/images/UGB_Store_logo.png" />
          </div>
        </div>
        <label for="username" class="form-label">Usuario:</label>
        <span class="p-input-icon-left">
          <i class="pi pi-user" />
          <InputText
            type="text"
            id="username"
            class="p-inputtext-lg"
            v-model="username"
            placeholder="Usuario"
            style="width: 100%"
          />
        </span>
        <label for="userpass" class="form-label">Contraseña:</label>
        <span class="p-input-icon-left">
          <i class="pi pi-lock" />
          <Password
            id="userpass"
            class="p-inputtext-lg"
            v-model="userpass"
            placeholder="Contraseña"
            name="password" 
            autocomplete="off"
            @keyup.enter="logAccess"
            style="width: 100%; background-color: #ffffff"
            :feedback="false"
            toggleMask
          ></Password>
        </span>
        <hr />
        <Button
          label="Ingresar"
          icon="pi pi-sign-in"
          iconPos="right"
          class="p-button-info"
          @click="logAccess"
        />
        <ProgressBar mode="indeterminate" v-show="loading" style="height: 0.5em" />
      </form>
    </div>
  </div>
</template>
<script>
import loginService from "../../service/Login";
import dataUser from "../../service/User";
import dataCampus from "../../service/Campus";
import Toast from "primevue/toast";
export default {
  components: {
    Toast,
  },
  emits: ["loadMenuInfo"],
  data() {
    return {
      loading: false,
      username: "",
      userpass: "",
    };
  },
  methods: {
    logAccess() {
      this.username = this.username.trim();
      this.userpass = this.userpass.trim();
      if (this.username.length > 0 && this.userpass.length > 0) {
        this.loading = true;
        this.loginService
          .access(this.username, this.userpass)
          .then((response) => {
            if (response.data) {
              this.loading = false;
              this.$store.commit("setAuthenticated", true);
              this.$store.commit("setUserId", response.data.codigo);
              this.$store.commit("setUsername", response.data.usuario);
              this.$store.commit("setUserGroup", response.data.grupo);
              this.$store.commit("setUserSede", response.data.sede);
              this.$store.commit("setUserRegional", response.data.regional)
              this.$store.commit("setUserToken", response.data.token);
              this.loadInfoCampus(response.data.sede);
              this.loadModules(response.data.grupo, response.data.sede);
              
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                this.logError("error", error.response.data.message);
              } else {
                this.logError("error", "Error de conexion, sin resultados");
              }
            } else {
              this.logError("error","Error de conexion, comunicarse con administrador de sistema.");
            }
            this.loading = false;
          });
      } else {
        this.logError("error", "Verifique campos vacios");
      }
    },
    loadModules(codigo, sede) {
      this.dataUser.accessModelule(codigo, sede)
        .then((response) => {
          if (response.data) {
            this.$store.commit("setModuleApp", response.data);
            this.$emit("loadMenuInfo");
            this.$router.push("/");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              this.logError("error", error.response.data.message);
            } else {
              this.logError("error", "Error de conexion, sin resultados");
            }
          } else {
            this.logError("error","Error de conexion, comunicarse con administrador de sistema.");
          }
        });
    },
    loadInfoCampus(codigo){
        this.dataCampus.getCampusID(codigo)
        .then((response) => {
          if (response.data) {
            this.$store.commit("setUserNSede", response.data[0].descripcion);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              this.logError("error", error.response.data.message);
            } else {
              this.logError("error", "Error de conexion, sin resultados");
            }
          } else {
            this.logError("error","Error de conexion, comunicarse con administrador de sistema.");
          }
        });
    },
    logError(status, value) {
      this.$toast.add({ severity: status, summary: "Acceso a sistema", detail: value, life: 3000, });
    },
  },
  mounted() {
    //this.$emit('processAuthenticated',this.processAuthenticated);
  },
  loginService: null,
  created() {
    this.loginService = new loginService();
    this.dataUser = new dataUser();
    this.dataCampus = new dataCampus();
  },
};
</script>
<style lang="scss" scoped>
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 40px;
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
</style>