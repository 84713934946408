import axios from 'axios';
//import store from "../store/index";
export default class dataCampus {
    async getCampusID(codigo){
        return await axios({
            method: 'get',
            url: '/Sedes/'+codigo
        });
    }
    async getModules(){
        let system = 1;
        return await axios({
            method: 'get',
            url: '/Sistema/modulos/'+system
        });
    }
}