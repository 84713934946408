<template>
  <div class="layout-footer">
    <span class="footer-text">UGBStore En Linea</span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
<style scoped></style>