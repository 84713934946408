import axios from "axios";
import store from "../store/index";
export default class salesReports{
    async getSalesReportBydatesPDF(isede, status, fechai, fechaf){
        let uId = store.state.uid;
        return await axios({
            method:'get',
            responseType: "blob",
            url:'ReportesVenta/xfechas/'+isede+'/'+status+'/'+fechai+'/'+fechaf+'/'+uId
        });
    }
    async getSalesReportBydatesXLS(isede, status, fechai, fechaf){
        let uId = store.state.uid;
        return await axios({
            method:'get',
            responseType: "blob",
            url:'ReportesVenta/xlsxfechas/'+isede+'/'+status+'/'+fechai+'/'+fechaf+'/'+uId
        });
    }
}